import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  foot: {
    position: 'fixed',
    left:0,
    bottom:0,
    right:0,
    flexGrow: 1,
    alignItems: 'center',
  },
  bijanlink: {
    color: 'white',
    textDecoration: "none",
    '&:hover': {
      textDecoration: "underline",
    }
  }
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <AppBar className={classes.foot} position="static" color="primary">
      <Container maxWidth="md">
          <Typography align="center"  variant="body1" color="inherit">
            © Copyright {new Date().getFullYear()} by <a className={classes.bijanlink} href = 'https://bijanmazaheri.com'>Bijan Mazaheri</a>
          </Typography>
      </Container>
     </AppBar>
  );
}