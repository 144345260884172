import * as React from 'react';
import { Typography, Box, IconButton, ButtonGroup, Button, Grid } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import MainSearch from "../components/MainSearch";
import {axios_get_data, PrettyRange, RunnerLACCTiC, RunnerLACCTiCNoRange} from "../config"
import UnpaginatedTable from '../components/UnpaginatedTable';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { simulateRaces, simulateOneRace } from "../simulateRaces";
import LinkIcon from '@mui/icons-material/Link';
import PublishIcon from '@mui/icons-material/Publish';
import BarChartIcon from '@mui/icons-material/BarChart';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router";
import ReactGA from 'react-ga4';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Histogram from "../components/Histogram";
import Publisher from "../components/Publisher";
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { lightBlue } from '@mui/material/colors'


const useStyles = makeStyles((theme) => ({
  sharableLinkBox: {
    width: "100%",
    maxWidth: "100%"
  }
}));


const Simulate = (props) => {
  const [runnersList, setRunnersList] = useState([]); // Tables Generated with this list
  const [teamsList, setTeamsList] = useState([]); // Tables generated with this list
  const [runners, setRunners] = useState([]); // Dictionary, but not used to generate table
  let [deletedRunners, setDeletedRunners] = useState([]);
  const [racePreview, setRacePreview] = useState(false);
  const [teams, setTeams] = useState([]); // Dictionary, not used to generate table
  const [simulated, setSimulated] = useState(false);
  const [simulating, setSimulating] = useState(false);
  const [addingRunners, setAddingRunners] = useState(false);
  const [addingTeams, setAddingTeams] = useState(false);
  let [avg_score_to_win, setAvgScoreToWin] = useState(null);
  const adding = addingRunners || addingTeams;
  const [sharing, setSharing] = useState(false);
  const isEmpty = (runnersList.length===0);
  const { theme } = props;
  const classes = useStyles(theme); 
  const { preloaded } = useParams();
  const [displayRanking, setDisplayRanking] = useState(false);
  var [includeNonRosters, setIncludeNonRosters] = useState(true)
  var [includeInactives, setIncludeInactives] = useState(true)
  const [metric, setMetric] = useState("Rating")

  const rescore_race = (runnerlist, teamlist) => {
    simulateOneRace(runnerlist, teamlist, metric)
    setRunnersList(runnerlist)
    setTeamsList(teamlist)
  }

  const validRunner = (runner) =>{
    return ((!deletedRunners.includes(runner.id)) &&
      (includeInactives || (runner.status === "Active")) &&
      (includeNonRosters || runner.on_roster) &&
      (runner.year_in_school !== "GR") &&
      (runner.year_in_school !== "RS"))
  }

  const fillTeamRoster = (team) => {
    let top7 = team.roster.filter(runner => validRunner(runner)).slice(0, 7);
    top7.forEach(runner => {
      if (!runnersList.includes(runner)){
        // Adding runner because they havent already been added
        runner.team = team; // because this info isnt in search results
        runners[runner.id] = runner;
      }
    });
  }

  // This function should be called whenever the field changes
  const reloadRunnersAndTeams = () => {
    teams.forEach(team => fillTeamRoster(team))
    let new_runners = Object.values(runners).filter(runner => validRunner(runner))
    setRunnersList(new_runners);
    rescore_race(new_runners, Object.values(teams));
    setSimulated(false);
    setAddingRunners(false);
    setAddingTeams(false);
  }

  const handleChangeActiveRunners = (event: React.ChangeEvent<HTMLInputElement>) => {
    includeInactives = event.target.checked;
    setIncludeInactives(event.target.checked);
    reloadRunnersAndTeams();
  };

  const handleChangeRosterRunners = (event: React.ChangeEvent<HTMLInputElement>) => {
    includeNonRosters = event.target.checked;
    setIncludeNonRosters(event.target.checked);
    reloadRunnersAndTeams();
  };

  const addRunners = (runners_to_add) => {
    runners_to_add.forEach(runner => {
      runners[runner.id] = runner;
    })
    let runners_to_add_ids = runners_to_add.map(runner => runner.id)
    deletedRunners = deletedRunners.filter(id => !runners_to_add_ids.includes(id));
    setDeletedRunners(deletedRunners);
  }

  const addTeams = (teams_to_add) => {
    const old_team_ids = Object.keys(teams);
    const old_team_vals = Object.values(teams);
    teams_to_add.forEach(team =>{
      teams[team.id] = team
    })
    // Get it down to 70
    let team_list = Object.values(teams)
    team_list.sort((a, b) => a.top_5_ability_average - b.top_5_ability_average);
    team_list.slice(70).forEach((team => {
      delete teams[team.id];
    }))
    // Now for each team not in teamIDs we will get its roster
    Object.values(teams).forEach(team => {
      // if we didnt have this team before, add its top 7
      if (!old_team_ids.includes(team.id)){
        fillTeamRoster(team);
      }
    })
    // Remove the runners from deleted teams (deleted if we went over 70)
    old_team_vals.forEach(old_team => {
      if (!Object.keys(teams).includes(old_team.id.toString())){
        old_team.roster.forEach(runner => delete runners[runner.id])
      }
    })
    reloadRunnersAndTeams();
  }

  const removeRunner = (runner_to_remove) => {
    delete runners[runner_to_remove.id];
    let varRunnersList = Object.values(runners);
    let runner_team = teamsList.filter(team => team.id === runner_to_remove.team.id)
    if (runner_team.length > 0){
      // Now this is a runner with a team, lets add a replacement
      deletedRunners.push(runner_to_remove.id)
      setDeletedRunners(deletedRunners);
      fillTeamRoster(runner_team[0])
    }
    if (varRunnersList.filter(runner => (runner_to_remove.team.id === runner.team.id)).length < 5){
      // no longer have 5 runners, remove
      removeTeamKeepRoster(runner_to_remove.team);
    }
    reloadRunnersAndTeams();
  }

  const removeTeamKeepRoster = (team_to_remove) => {
    delete teams[team_to_remove.id];
    reloadRunnersAndTeams();
  }

  const removeTeams = (teams_to_remove) => {
    teams_to_remove.forEach((team_to_remove) => {
      delete teams[team_to_remove.id]
      team_to_remove.roster.forEach(runner => {
        delete runners[runner.id];
      })
    })
    reloadRunnersAndTeams();
  }

  const addLeague = (league_to_addid, maxteams) => {
    axios_get_data(`/api_ranking/sim_teams/?leagues=${league_to_addid}&nonull=true`).then( ( response ) => {
      let newTeams = response.data.results;
      // get teams down to maxteams
      newTeams = newTeams.slice(0,maxteams)
      addTeams(newTeams);
      axios_get_data(`/api_ranking/sim_runners/?team__leagues=${league_to_addid}&notgraduated=true`).then( ( response ) => {
        let newRunners = response.data.results;
        newRunners = newRunners.filter( (runner) => !Object.keys(teams).includes(runner.team.id.toString()))
        addRunners(newRunners);
      });
    });
  }

  const addLeagueHandler = (l) => {
    setAddingRunners(true);
    setAddingTeams(true);
    addLeague(l.id, 35)
  }
  const addLeagueBeginningHandler = (l_id, rprev) => {
    setAddingRunners(true);
    setAddingTeams(true);
    if (rprev){
      addLeague(l_id, 70)
    } else {
      addLeague(l_id, 35)
    }
  }
  const addTeamHandler = (t) => {
    setAddingTeams(true);
    axios_get_data(`/api_ranking/sim_teams/${t.id}`).then( ( response ) => {
      addTeams([response.data]);
    });
    reloadRunnersAndTeams();
  }
  const addRunnerHandler = (r) => {
    setAddingRunners(true);
    axios_get_data(`/api_ranking/sim_runners/${r.id}`).then( ( response ) => {
      addRunners([response.data]);
    });
    reloadRunnersAndTeams();
  }

  const simulationHandler = () => {
    setSimulating(true);
    avg_score_to_win = simulateRaces(runnersList, teamsList, 1000);
    setTeamsList([...teamsList]);
    setRunnersList([...runnersList]);
    setSimulating(false);
    setSimulated(true);
    setAvgScoreToWin(avg_score_to_win);
    ReactGA.event({
      category: 'Simulation',
      action: 'Simulated a Race'
    });
  }

  const ratingScoreHandler = () => {
    setSimulated(false);
    rescore_race(Object.values(runners), Object.values(teams));
  }

  const clearHandler = () => {
    setSimulated(false);
    setTeams({});
    setRunners({});
    setTeamsList([]);
    setRunnersList([]);
  }

  useEffect(() => {
    if (preloaded){
      let splt = preloaded.split('&');
      let teamIDsString = splt[0].split('=')[1];
      let runnerIDsString = splt[1].split('=')[1];
      let leagueIDString = splt[2].split('=')[1];
      let deletedIDString = splt[3].split('=')[1];
      setRacePreview(splt.length > 4);
      if (leagueIDString !== ""){
        addLeagueBeginningHandler(leagueIDString, splt.length > 4);
      }
      if (deletedIDString !== ""){
        let newDeletedRunners = deletedIDString.split(",").map(id => Number(id));
        setDeletedRunners(newDeletedRunners);
      }
      if (teamIDsString !== ""){
        setAddingTeams(true);
        axios_get_data(`/api_ranking/sim_teams/?ids=${teamIDsString}`).then( ( response ) => {
          let newTeams = response.data.results;
          setAddingTeams(false);
          addTeams(newTeams.slice(0, 50));
        });
      }
      if (runnerIDsString !== ""){
        setAddingRunners(true);
        axios_get_data(`/api_ranking/sim_runners/?ids=${runnerIDsString}`).then( ( response ) => {
          let newRunners = response.data.results;
          setAddingRunners(false);
          addRunners(newRunners);
        });
      }
    }
  }, [])

  const generateQueryString = (teamlist, runnerlist) => {
    let teamIDs = teamlist.map(team => team.id);
    let individualRunnerIDs = runnerlist.filter(runner => !teamIDs.includes(runner.team.id)).map(runner => runner.id);
    let qstring = `www.lacctic.com/simulate/teamIDs=${teamIDs.join()}&individualRunnerIDs=${individualRunnerIDs.join()}&league=&deleted=${deletedRunners.join()}`;
    if (racePreview) {
      qstring = qstring + '&racepreview'
    }
    return qstring;
  }

  const shareHandler = () => {
    setSharing(!sharing);
    ReactGA.event({
      category: 'Simulation',
      action: 'Generated share link'
    });
  }

  const publishHandler = () => {
    setDisplayRanking(!displayRanking);
  }

  if (simulated){
    const sort_by_med_then_avg = (a, b) => {
      const med_diff = a.med_finish - b.med_finish;
      if (med_diff === 0) {
        return a.avg_finish - b.avg_finish;
      } else {
        return med_diff;
      }
    }
    runnersList.sort(sort_by_med_then_avg);
    teamsList.sort((a, b) => a.avg_finish - b.avg_finish);
  }


  ////////////////////////////////////////
  //      Before Simulating Data Processors
  ////////////////////////////////////////

  const teamHeadLabels = [
    {label:"Team"}, 
    {label:"Score"}, 
    {label:""}];

  const teamDataProcessors = [
    (team) => (<Link to={`/teams/${team.id}`}>{team.name}</Link>),
    (team) => team.score,
    (team) => (<IconButton aria-label="delete" size = "small" onClick={() => removeTeams([team])}>
                  <DeleteIcon />
                </IconButton>)
  ];

  const runnerHeadLabels = [
    {label: "Name"}, 
    {label: metric, info: "An estimate of the runner's 5k fitness."},
    {label: "Team"},
    {label: "Year"}, 
    {label: ""}];

  const runnerDataProcessors = [
    (runner) => (<Link to={`/runners/${runner.id}`}>{runner.firstname+ " " + runner.lastname}</Link>),
    (runner) => (<RunnerLACCTiC runner = {runner}/>),
    (runner) => (<Link to={`/teams/${runner.team.id}`}> {runner.team.name} </Link>),
    (runner) => runner.year_in_school,
    (runner) => (<IconButton aria-label="delete" size="small" onClick={() => removeRunner(runner)}>
                  <DeleteIcon />
                </IconButton>)
  ];

  const teamPreSimulationExpander = (team) => {
    const top_runners = runnersList.filter(runner => (runner.team.id === team.id)).slice(0, 7);
    return(
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Roster</Box></Typography></TableCell>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >{metric}</Box></Typography></TableCell>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Score</Box></Typography></TableCell>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Year</Box></Typography></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {top_runners.map((runner) => (
                <TableRow key={runner.firstname + runner.lastname}>
                  <TableCell>
                    <Typography style={{fontSize:"12px"}}><Link to={`/runners/${runner.id}`}>{runner.firstname+ " " + runner.lastname}</Link></Typography>
                  </TableCell>
                  <TableCell><Typography style={{fontSize:"12px"}}><RunnerLACCTiC runner = {runner}/></Typography></TableCell>
                  <TableCell><Typography style={{fontSize:"12px"}}>{runner.score}</Typography></TableCell>
                  <TableCell><Typography style={{fontSize:"12px"}}>{runner.year_in_school}</Typography></TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" size="small" onClick={() => removeRunner(runner)}>
                      <DeleteIcon style={{width:"18", height:"18"}}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    )
  }
  ////////////////////////////////////////
  //      After Simulating Data Processors
  ////////////////////////////////////////
  const teamHeadLabelsSim = [
    {label:"Team"}, 
    {label:"Place", info:"The average finishing place over 1000 simulations."}, 
    {label:"Score", info:"The median team score over 1000 simulations."}, 
    {label:"Win %", info:"The probability of winning over 1000 simulations."}, 
    {label:""}];
  const teamDataProcessorsSim = [
    (team) => (<Link to={`/teams/${team.id}`}>{team.name}</Link>),
    (team) => (<Fragment>{team.finishes && <PrettyRange lower={team.lower_finish} upper={team.upper_finish} avg={team.avg_finish}/>}</Fragment>),
    (team) => <Fragment>{team.scores && <PrettyRange lower={team.lower_score} upper={team.upper_score} avg={team.med_score}/>}</Fragment>,
    (team) => <Fragment>{team.scores && team.win_chance + "%"}</Fragment>,
    (team) => (<IconButton aria-label="delete" size = "small" onClick={() => removeTeams([team])}>
                  <DeleteIcon/>
                </IconButton>)
  ];
  const teamExpander = (team) => {
    const top_runners = runnersList.filter(runner => (runner.team.id === team.id)).slice(0, 7);
    top_runners.sort((a, b) => a.ability - b.ability);
    return(
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Roster</Box></Typography></TableCell>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Rating</Box></Typography></TableCell>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Median Score</Box></Typography></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {top_runners.map((runner) => (
                <TableRow key={runner.firstname + runner.lastname}>
                  <TableCell>
                    <Typography style={{fontSize:"12px"}}><Link to={`/runners/${runner.id}`}>{runner.firstname+ " " + runner.lastname}</Link></Typography>
                  </TableCell>
                  <TableCell><Typography style={{fontSize:"12px"}}><RunnerLACCTiCNoRange runner = {runner}/></Typography></TableCell>
                  <TableCell>
                    {runner.med_score && <Typography style={{fontSize:"12px"}}>{runner.med_score}</Typography>}
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="delete" size="small" onClick={() => removeRunner(runner)}>
                      <DeleteIcon style={{width:"18", height:"18"}}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Histogram dataset_label="Finishes" values={team.finishes}/>
        </Grid>
      </Grid>
    )
  }
  const runnerExpander = (runner) => {
    return(
      <Fragment>
        {runner.finishes && <Histogram dataset_label="Finishes" values={runner.finishes}/>}
      </Fragment>
    )
  }
  const runnerHeadLabelsSim = [
    {label:"Name"}, 
    {label:"Place", info:"The median finishing place over 1000 simulations."},
    {label:"Win %", info:"The probability of winning over 1000 simulations."},
    {label: "Rating", info: "An estimate of the runner's 5k fitness."},
    {label:"Team"},
    {label: ""}];
  
  const runnerDataProcessorsSim = [
    (runner) => (<Link to={`/runners/${runner.id}`}>{runner.firstname+ " " + runner.lastname}</Link>),
    (runner) => (<Fragment>{ runner.finishes && <PrettyRange lower={runner.lower_score} upper={runner.upper_score} avg={runner.med_finish}/>}</Fragment>),
    (runner) => (<Fragment>{runner.finishes && runner.win_chance + "%"}</Fragment>),
    (runner) => (<RunnerLACCTiCNoRange runner = {runner}/>),
    (runner) => (<Link to={`/teams/${runner.team.id}`}> {runner.team.name} </Link>),
    (runner) => (<IconButton aria-label="delete" size="small" onClick={() => removeRunner(runner)}>
                  <DeleteIcon/>
                </IconButton>)
  ];
  ////////////////////////////////////////
  ////////////////////////////////////////
  
  return (
    <Grid className="home" container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          LACCTiC Simulator
        </Typography>
        <Typography variant="body1"  color="textPrimary" gutterBottom>
          This app will simulate a cross country race using LACCTiC ratings. 
          Use the search bar to add leagues, teams, and runners. 
          You can delete runners and teams by clicking on trash can next to them. 
          If you delete a runner after you have simulated the race, the simulator will revert back to a "unsimulated" state. 
          Just click "simulate" again when you are done modifying the field!
          The simulator is limited to at most 70 teams.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MainSearch
          handlers = {{
            addLeagueHandler,
            addTeamHandler,
            addRunnerHandler,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ButtonGroup aria-label="sharing button group">
          <Button startIcon={<DeleteIcon/>} variant="contained" color="inherit" size="medium" 
            onClick={clearHandler}
            disabled={isEmpty || adding || simulating}>
            Clear
          </Button>
          <Button startIcon={<PublishIcon/>} variant="contained" color="inherit" size="medium"
            onClick={publishHandler}
            disabled={!simulated}>
            Publish
          </Button>
          <Button startIcon={<LinkIcon/>} variant="contained" color="inherit" size="medium"
            onClick={shareHandler}
            disabled={isEmpty}>
            Share
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
      <ButtonGroup aria-label="primary button group">
      <Button startIcon = {<DirectionsRunIcon/>} variant="contained" color="secondary" size="medium"
            onClick={ratingScoreHandler}
            disabled={! simulated}>
            Ratings
      </Button>
      <Button startIcon = {<BarChartIcon/>} variant="contained" color="secondary" size="medium"
            onClick={simulationHandler}
            disabled={isEmpty || adding || simulating || simulated}>
            {!adding && "Simulation"}{adding && "Loading..."}
      </Button>
      </ButtonGroup>
      <Grid item xs={12}>
        <FormControlLabel control={
          <Checkbox sx={{
            color: lightBlue[400],
            '&.Mui-checked': {
              color: lightBlue[400],
            },
          }}
          checked={includeInactives}
          onChange={handleChangeActiveRunners}
          />
        } label="Include inactive runners"/>
        <FormControlLabel control={
          <Checkbox sx={{
            color: lightBlue[400],
            '&.Mui-checked': {
              color: lightBlue[400],
            },
          }}
          checked={includeNonRosters}
          onChange={handleChangeRosterRunners}
          />
        } label="Include runners not on roster"/>
      </Grid>
      </Grid>
      { simulated &&
      <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Average Winning Score: {Math.round(avg_score_to_win)}
            </Typography>
      </Grid>}
      {displayRanking &&
      <Grid item xs = {12}>
        <Paper className={classes.paper} style={{borderRadius: "6px"}} color="primary">
        <Publisher 
          listOfTeams = {teamsList} 
          listOfRunners = {runnersList}
        />
        </Paper>
      </Grid>}
      {sharing && 
      <Grid item xs={12}>
        <textarea readOnly={true} className={classes.sharableLinkBox}>
          {generateQueryString(teamsList, runnersList)}
      </textarea>
      </Grid>}
      <Grid item xs = {12}>
      </Grid>
      {!simulated && 
      <>
        <Grid item xs={12}>
            <UnpaginatedTable 
              localData = { teamsList }
              title = "Teams" 
              headLabels = { teamHeadLabels } 
              dataProcessors = { teamDataProcessors } 
              minwidth={450}
              rank = {true}
              expander = {teamPreSimulationExpander}
            />
        </Grid>
        <Grid item xs={12}>
            <UnpaginatedTable
              localData = { runnersList }
              title = "Runners" 
              headLabels = { runnerHeadLabels } 
              dataProcessors = { runnerDataProcessors } 
              minwidth={550}
              rank = {true}
            />
        </Grid>
      </> }
      {simulated && 
      <>
        <Grid item xs={12}>
            <UnpaginatedTable 
              localData = { teamsList }
              title = "Teams" 
              headLabels = { teamHeadLabelsSim } 
              dataProcessors = { teamDataProcessorsSim } 
              minwidth={450}
              rank = {true}
              expander = {teamExpander}
            />
        </Grid>
        <Grid item xs={12}>
            <UnpaginatedTable
              localData = { runnersList }
              title = "Runners" 
              headLabels = { runnerHeadLabelsSim } 
              dataProcessors = { runnerDataProcessorsSim } 
              minwidth={550}
              rank = {true}
              expander = {runnerExpander}
            />
        </Grid>
      </> }
    </Grid>
  )
}

export default Simulate;