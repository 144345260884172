import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px',
    margin: '5px',
    flexGrow: 1,
    textAlign: 'left',
  },
  maintitle: {
    textAlign: 'center',
    color: "text.primary"
  },
  paragraph: {
    color: "text.primary",
  },
  subtitle: {
    color: "textPrimary",
  }
}));

const About = () => {
  const classes = useStyles();
  return (
    <Container className="about" >
      <Typography variant="h4" className={classes.maintitle} gutterBottom color = "textPrimary">About</Typography>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs = {12}>
          <Typography variant = "body1" gutterBottom color = "textPrimary">
            For frequently asked questions, see the <Link to = "/faq/">FAQ</Link>. For a tutorial on how to use the website, see the <Link to = "/tutorial/">introduction</Link>.
          </Typography>
        </Grid>
        <Grid item xs = {12}>
          <Typography className={classes.subtitle} variant = "h6" gutterBottom color = "textPrimary"> Adjusting Courses </Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
            No two cross country races are the same. Terrain, weather, and measurement errors make
            races difficult to compare. LACCTiC aggregates hundreds of thousands of performances to find performance trends across different races. 
            This information is used to convert performances to a single comparable metric: "TiCs," which stands for "<strong>Ti</strong>me <strong>C</strong>omparison". 
          </Typography>
          <Typography>
            <strong>The primary component in your TiC score is how you perform relative to your
            peers in cross country races, and how your peers perform relative to others.</strong> 
            It does not matter why a course is slow or fast - all that matters is that all runners are affected equally.
          </Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
            TiCs can be roughly be interpretaed as "5k equivalents," giving the average 5k PR of people who peform similarly.
            The conversion to track performances is for interpretability and not necesarily an indicator of 5k fitness. For example, an excellent cross country
            runner may have much better TiCs than track times.
          </Typography>
        </Grid>
        <Grid item xs = {12}>
          <Typography className={classes.subtitle} variant = "h6" gutterBottom color = "textPrimary">Ranking Individuals</Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary"> 
            An appropriately weighted average yeilds an estimate of the athlete's current 5k fitness. 
            The current system focuses on an athlete's top overall performance and top performance at an "important" race. 
            The performances are weighted according to their importance. In addition, previous season fitnesses and track races are occasionally included to improve accuracy.
          </Typography>
        </Grid>
        <Grid item xs = {12}>
          <Typography className={classes.subtitle} variant = "h6" gutterBottom color = "textPrimary">Simulating Races</Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
            Cross country is scored on <em>relative</em> performances rather than absolute times --
            a weak 5th runner may not affect a dual meet significantly, but could score over 300 points at a national meet. The sport is also <em>stochastic</em> -- 
            a team with strong 6th and 7th runnings is much more robust to chaotic post-season races.
            LACCTiC provides rankings that incorporate this relativity and stochasticity via <em>Monte-Carlo simulation</em>. 
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography variant = "body1" gutterBottom color = "textPrimary">
          This website is built and maintained by <a href = 'https://bijanmazaheri.com'>Bijan Mazaheri</a>, a Postdoc at MIT/Harvard and an alumus of
          Caltech and Williams College.
        </Typography>
      </Grid>
      </Grid>
    </Container>
  )
}

export default About;