import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

//const TRACKING_ID = "UA-206649060-1";
const TRACKING_ID = "G-YLC6GCTSVK";
ReactGA.initialize(TRACKING_ID, {
    debug: false,
    testMode: false,});
    //gaAddress: 'https://ssl.google-analytics.com/ga.js',});
    

const RouteChangeTracker = () => {
    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});
        //console.log(window.location.pathname + window.location.search) //THIS RUNS
    });
    return <div></div>;
};

export default withRouter(RouteChangeTracker);