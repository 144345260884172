import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { axios_set_data, TeamLACCTiC, RunnerLACCTiC, shortDate } from '../config';
import PaginatedTable from '../components/PaginatedTable';
import ReactGA from 'react-ga4';
import { Button } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    margin: '10px',
    flexGrow: 1,
    textAlign: 'left',
  },
  primary: {
    textAlign: 'left',
  },
  table: {
    minWidth: 450,
    marginTop: "0",
    marginBottom: "10",
    padding: "0",
  },
  tableContainer: {
    maxHeight: 300,
    backgroundColor: "white"
  },
  infoGrid: {
    margin: "0 0 40 0",
    padding: "20 20 20 20"
  },
  label: {
    fontWeight:"fontWeightMedium"
  }
}));

const generate_simualtion_link = (leagueData) => {
  let l = `/simulate/teamIDs=&individualRunnerIDs=&league=${leagueData.id}&deleted=`
  if (leagueData.type == "Race"){
    l += '&racepreview';
  }
  return l;
}

function League(props) {
  const { id } = useParams();
  const [leagueData, setLeagueData] = useState(null);
  const leagueURL = encodeURI(`/api_ranking/leagues/${id}`);
  const teamURL = encodeURI(`/api_ranking/league_teams/?leagues=${id}&nonull=true`);
  const runnerURL = encodeURI(`/api_ranking/league_runners/?team__leagues=${id}`);
  const raceURL = encodeURI(`/api_ranking/league_races/?participating_teams__leagues=${id}`);
  const { theme } = props;
  const classes = useStyles(theme);

  const teamHeadLabels = [
    {label:"Team"}, 
    {label:"Top 5 Avg", info:"The average LACCTiC rating of the team's top 5 active runners."}];
    
  const teamDataProcessors = [
    (team) => (<Link to={`/teams/${team.id}`}>{team.name}</Link>),
    (team) => (<TeamLACCTiC team = {team}/>)
  ];

  const runnerHeadLabels = [
    {label: "Name"}, 
    {label: "Rating", info: "An estimate of the runner's 5k fitness."},
    {label: "Team"},
    {label: "Year"}];

  const runnerDataProcessors = [
    (runner) => (<Link to={`/runners/${runner.id}`}>{runner.firstname+ " " + runner.lastname}</Link>),
    (runner) => (<RunnerLACCTiC runner = {runner}/>),
    (runner) => (<Link to={`/teams/${runner.team.id}`}> {runner.team.name} </Link>),
    (runner) => runner.year_in_school,
  ];

  useEffect(() => {
    axios_set_data(leagueURL, setLeagueData, (x) => {});
    ReactGA.event({
      category: 'Explore',
      action: 'Viewed league page'
    });
  }, [])

  const raceHeadLabels = [
    {label: "Date"}, 
    {label: "Race"}];

  const raceDataProcessors = [
      (race) => (shortDate(race.date)),
      (race) => (<Link to = {`/races/${race.id}`}>{race.meet_name + " - " +race.section}</Link>),
    ];

  return (
    <Container className={classes.root}>
      <Grid className = {classes.infogrid} container spacing={5}>
        {leagueData && (
          <>
            <Grid item xs={6} className={classes.primary}>
              <Typography variant="h4" component="h4" color = "textPrimary">
               {leagueData.name}
              </Typography>
              <Typography variant = "h6" color = "textSecondary">
                {leagueData.detail_string}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{textAlign: "right"}}>
              <Button component = {Link} 
                to={generate_simualtion_link(leagueData)} 
                variant="contained" 
                color="secondary" 
                size="large"
              >
                  Simulate/Rank {leagueData.name}
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <PaginatedTable 
            url = { teamURL } 
            title = "Teams" 
            headLabels = { teamHeadLabels } 
            dataProcessors = { teamDataProcessors } 
            minwidth={450}
            rank = {true}
            ranklabel = {{label: "Pseudo-Rank", info: "True rankings should be obtained by using the simulator to score a race. To simulate this league click the blue button on the top right of this page."}}
          />
        </Grid>
        <Grid item xs={12}>
          <PaginatedTable
            url = { runnerURL } 
            title = "Runners" 
            headLabels = { runnerHeadLabels } 
            dataProcessors = { runnerDataProcessors } 
            minwidth={550}
            rank = {true}
          />
        </Grid>
        <Grid item xs={12}>
          <PaginatedTable
            title = "Recent Races"
            url = { raceURL }
            headLabels = { raceHeadLabels } 
            dataProcessors = { raceDataProcessors } 
            minwidth={550}
            rank = {false}
          />
        </Grid>   
      </Grid>
    </Container>
  );
}

export default League;
