import {Bar} from "react-chartjs-2"


export default function Histogram(props){
  const {dataset_label, values} = props;
  const counts = {};
  values.forEach(value => {
    if (counts[value]){
      counts[value] = counts[value] + 1;
    } else {
      counts[value] = 1;
    }
  })
  const support_min = Math.min(...values);
  const support_max = Math.max(...values);
  let count_labels = [];
  let data_counts = [];
  for (let i = support_min; i <= support_max; i++){
    count_labels.push(i)
    data_counts.push(Number(counts[i]));
  }
  const options = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
        },
        title: {
          text: "Count"
        },
      },],
      x: {
        title: {
          text: "Place"
        },
      },
    },
    plugins: {
      title: {
        text: "Finishing Place Histogram",
        display: true
      },
      legend: {
        display: false
      }
    },
    maintainAspectRatio: false,
  };
  const data = {
    labels: count_labels,
    datasets: [{
      label: dataset_label,
      data: data_counts,
      backgroundColor: '#81d4fa',
      borderColor: '#0277bd',
      borderWidth: 1,
    }]
  };
  return (
    <Bar
      data={data}
      options = {options}
    />
  )
}