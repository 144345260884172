import { Typography } from "@material-ui/core";
import { Fragment } from "react";
import { Link } from 'react-router-dom';

export default function LeagueList( props ){
  let {leagues} = props;
  let league_dict = {
    "": null,
    "Gender": null,
    "Association": null,
    "Division": null,
    "Region": null,
  };
  let Conferences = [];

  leagues.forEach((league) => {
    if (league.type === "Conference"){
      Conferences = Conferences.concat([league]);
    } else {
      league_dict[league.type] = league;
    }
  });
  let path = ["", "Gender", "Association", "Division", "Region"].map(type => league_dict[type]);

  return (
    <Fragment>
        <Typography styles={{fontSize:"16px"}}>
          {path.map((league, i) => {
            return [i > 0 && league && " > ", league && <Link key = {league.name} to = {`/leagues/${league.id}`}> {league.name}</Link>]
          })}
        </Typography>
        <Typography separator="," styles={{fontSize:"16px"}}>
          {Conferences.map((league, i) => [i > 0 && ", ", <Link key = {league.name} to = {`/leagues/${league.id}`}> {league.name}</Link>])}
        </Typography>
    </Fragment>
  )
}