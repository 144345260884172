import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px',
    margin: '5px',
    flexGrow: 1,
    textAlign: 'left',
  },
  maintitle: {
    textAlign: 'center',
    color: "text.primary"
  },
  paragraph: {
    color: "text.primary",
  },
  subtitle: {
    color: "textPrimary",
  }
}));

const Donate = () => {
  const classes = useStyles();
  return (
    <Container className="Donate" >
      <Container className={classes.root}>
        <Typography variant = "h4" gutterBottom color="textPrimary">Donations</Typography>
        <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
          I developed these algorithms and the accompanying website on my own. It took a ton of time, and the server it is hosted on costs money.
          If you are enjoying the site please consider donating!
        </Typography>
        <form action="https://www.paypal.com/donate" method="post" target="_top">
          <input type="hidden" name="hosted_button_id" value="ZBR52Q66LGLUL" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
        <Button target="_blank" href='https://www.patreon.com/LACCTiC' variant="contained" color="secondary" size="small">
          Patreon
        </Button>
        <Typography variant = "h4" gutterBottom color="textPrimary">Sponsoring</Typography>
        <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
          If you or your company would like to sponsor the site, please contact me!
        </Typography>
      </Container>
    </Container>
  )
}

export default Donate;