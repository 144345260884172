
function randn_bm(mean, std, myrng) {
  let u = 1 - myrng(); //Converting [0,1) to (0,1)
  let v = myrng();
  return mean + (std *(Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v )));
}

function randn_both(mean, std, myrng){
  if (myrng() >= .33){
    return randn_bm(mean, std/2, myrng)
  } else {
    return randn_bm(mean, std, myrng)
  }
}

function mean(list_of_vals){
  return list_of_vals.reduce((a, b) => a + b, 0) / list_of_vals.length;
}

function median(values){
  if(values.length ===0) throw new Error("No inputs");

  values.sort(function(a,b){
    return a-b;
  });

  var half = Math.floor(values.length / 2);
  
  if (values.length % 2)
    return values[half];
  
  return (values[half - 1] + values[half]) / 2.0;
}

function getStats(runnerOrTeam){
  // First get score stats
  let list_of_vals = runnerOrTeam.scores
  list_of_vals.sort((a, b) => a - b);
  runnerOrTeam.lower_score = list_of_vals[Math.round(.16 * list_of_vals.length) - 1]
  runnerOrTeam.upper_score = list_of_vals[Math.round(.84 * list_of_vals.length) - 1]
  runnerOrTeam.avg_score = mean(list_of_vals);
  runnerOrTeam.med_score = median(list_of_vals);

  // Now get finish stats
  list_of_vals = runnerOrTeam.finishes
  let simulations = list_of_vals.length
  list_of_vals.sort((a, b) => a - b);
  runnerOrTeam.lower_finish = list_of_vals[Math.round(.16 * list_of_vals.length) - 1]
  runnerOrTeam.upper_finish = list_of_vals[Math.round(.84 * list_of_vals.length) - 1]

  runnerOrTeam.win_chance = 0
  runnerOrTeam.two_chance = 0
  runnerOrTeam.three_chance = 0
  runnerOrTeam.four_chance = 0
  list_of_vals.forEach(place => {
    if (place <= 1){
      runnerOrTeam.win_chance += 1
    }
    if (place <= 2){
      runnerOrTeam.two_chance += 1
    }
    if (place <= 3){
      runnerOrTeam.three_chance += 1
    }
    if (place <= 4){
      runnerOrTeam.four_chance += 1
    }
  })
  runnerOrTeam.win_chance = Math.round(runnerOrTeam.win_chance / simulations * 100);
  runnerOrTeam.two_chance = Math.round(runnerOrTeam.two_chance / simulations * 100);
  runnerOrTeam.three_chance = Math.round(runnerOrTeam.three_chance / simulations * 100);
  runnerOrTeam.four_chance = Math.round(runnerOrTeam.four_chance / simulations * 100);

  runnerOrTeam.avg_finish = mean(list_of_vals);
  runnerOrTeam.med_finish = median(list_of_vals);
}

const scoreSimulation = (teams, runners, teamIDs) => {
  let score_counter = 1;
  let teamScore = {};
  let teamIndividualCounter = {};
  teams.forEach( team => {
    teamIndividualCounter[team.id] = 0;
    teamScore[team.id] = 0;
  })
  runners.forEach((runner, place) => {
    runner.score = score_counter;
    runner.finish = place+1;
    if (teamIDs.has(runner.team.id)){
      // if we havent hit the 5th man yet, we count the score
      if (teamIndividualCounter[runner.team.id] < 5){
        teamScore[runner.team.id] = teamScore[runner.team.id] + score_counter ;
      }
      // if we havent hit the 7th man yet, we increment the score counter
      if (teamIndividualCounter[runner.team.id] < 7){
        score_counter = score_counter + 1;
        teamIndividualCounter[runner.team.id] = teamIndividualCounter[runner.team.id] + 1;
      }
    }
  })
  teams.sort((a, b) => teamScore[a.id] - teamScore[b.id]);
  let score_to_win = 0;
  if (teams[0]){
    score_to_win = teamScore[teams[0].id];
  }
  teams.forEach((team, place) =>{
    team.score = teamScore[team.id];
    team.finish = place + 1;
  })
  return score_to_win
}

export function simulateOneRace(runners, teams, metric){
  const teamIDs = new Set(teams.map(team => team.id))
  switch (metric) {
    case "Best TiC":
      runners.sort((a, b) => a.best_performance - b.best_performance)
      break;
    case "Season Best TiC":
      runners.sort((a, b) => a.current_season_best - b.current_season_best)
      break;
    default:
      runners.sort((a, b) => a.ability - b.ability)
  }
  scoreSimulation(teams, runners, teamIDs);
}

export function simulateRaces(runners, teams, simulations) {
  // first initialize stuff to 0
  const teamIDs = new Set(teams.map(team => team.id))
  runners.forEach((runner)=>{
    runner.finishes = [];
    runner.scores = [];
  })
  teams.forEach((team)=>{
    team.scores = [];
    team.finishes = [];
  })

  const singleSimulation = (myrng) => {
    runners.forEach(runner => {
      runner.random_performance = randn_both(runner.ability, runner.ability_std, myrng);
    })
    runners.sort((a, b) => a.random_performance - b.random_performance)
  }

  let scores_to_win = []
  const seedrandom = require('seedrandom');
  const myrng = seedrandom(427);
  for (let i = 0; i < simulations; i++) {
    singleSimulation(myrng);
    let score_to_win = scoreSimulation(teams, runners, teamIDs);
    teams.forEach((team) =>{
      team.finishes.push(team.finish)
      team.scores.push(team.score)
    })
    runners.forEach((runner) =>{
      runner.finishes.push(runner.finish)
      runner.scores.push(runner.score)
    })
    scores_to_win.push(score_to_win)
  }
  teams.forEach((team) =>{
    getStats(team);
  })
  runners.forEach((runner) =>{
    getStats(runner);
  })
  return mean(scores_to_win);
}