import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { axios_set_data } from '../config';
import { useState, useEffect, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { Box } from '@material-ui/core';
import LoadingBox from './LoadingBox';

const useStyles = makeStyles((theme, minwidth) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: minwidth,
    backgroundColor: "white"
  },
  tableContainer: {
    maxHeight: 500,
    backgroundColor: grey[800]
  },
  tableheader:{
    whiteSpace: "nowrap",
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: "0",
    padding: "0"
  }
}));



export function EnhancedTableHead(props) {
  const { ranklabel, headLabels, rank, expander} = props;
  const classes = useStyles();
  return (
    headLabels && <TableHead>
      <TableRow>
        {expander && 
          <TableCell key={"expander"}>
          </TableCell>
        }
        {rank && 
          <TableCell key={"rank"}>
            <Box className={classes.tableheader}>
              <Typography fontWeight="fontWeightBold" style={{fontSize:"14px"}} m={1} className={classes.tableheader}>
              <Box fontWeight="fontWeightBold">
                {ranklabel &&
                  <Box className={classes.tableheader}>
                    <Typography fontWeight="fontWeightBold" style={{fontSize:"14px"}} m={1} className={classes.tableheader}>
                    <Box fontWeight="fontWeightBold"> 
                      {ranklabel.label +'\xa0'}
                    </Box>
                    </Typography>{ranklabel.info && <Tooltip title={ranklabel.info}><HelpOutlineIcon style={{ color: grey[500] }} fontSize="small"/></Tooltip>}
                  </Box>
                }
                {!ranklabel &&
                  "Rank"
                }
              </Box>
              </Typography>
            </Box>
          </TableCell>
        }
        {headLabels.map((headLabel) => (
          <TableCell key={headLabel.label+"head"} colSpan={headLabel.colSpan?headLabel.colSpan:1}>
            <Box className={classes.tableheader}>
              <Typography fontWeight="fontWeightBold" style={{fontSize:"14px"}} m={1} className={classes.tableheader}>
                <Box fontWeight="fontWeightBold"> 
                  {headLabel.label +'\xa0'}
                </Box>
              </Typography>
              {headLabel.info && <Tooltip title={headLabel.info}><HelpOutlineIcon style={{ color: grey[500] }} fontSize="small"/></Tooltip>}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: grey[800],
    color: "white",
    borderRadius: "6px 6px 0px 0px"
  },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title, secondary_title, under_title } = props;
  var main_spacing = secondary_title?7:12;
  return (
    <Toolbar className={classes.root}>
      <Grid className = {classes.infogrid} container spacing={0}>
        <Grid item xs={main_spacing} className={classes.primary}>
          <Typography className={classes.title} variant="h6" id="tableTitle" >
            {title}
          </Typography>
        </Grid>
        {secondary_title && <Grid item xs={5} className={classes.secondary}>
          <Typography className={classes.title} variant="subtitle1" id="table_second_title" align='right'>
            {secondary_title}
          </Typography>
        </Grid>}
        {under_title && <Grid item xs={12} className={classes.under}>
          <Typography className={classes.title} variant="subtitle2" id="table_under_title" >
            {under_title}
          </Typography>
        </Grid>}
      </Grid>
    </Toolbar>
  );
};


function RowGenerator(props) {
  const {index, row, dataProcessors, rank, expander, predicted_place} = props;
  const [open, setOpen] = useState(false);
  let number_of_cols = dataProcessors.length
  if (rank) {
    number_of_cols += 1;
  }
  if(expander) {
    number_of_cols +=1;
  }
  return (
    <Fragment>
      <TableRow key={"row" + index}>
        {expander &&
          <TableCell key={"expand #"+index}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        }
        {rank && 
          <TableCell key={"rank #"+index}>
            <Typography style={{fontSize:"14px"}}>{index + 1 }</Typography>
          </TableCell>
        }
          {dataProcessors.map((f, index2) => (
            <TableCell key={`element ${index} ${index2}`}>
              <Typography style={{fontSize:"14px"}}>{f(row)}</Typography>
            </TableCell>
          ))}
      </TableRow>
      {expander &&
        <TableRow>
        <TableCell key={"expand "+index} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={number_of_cols}>
          <Collapse key={"collapse "+index} in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {expander(row)}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      }
    </Fragment>
  )
}


export default function UnpaginatedTable(props) {
  const {under_title, secondary_title, expander, localData, localData2, url, title, headLabels, dataProcessors, minwidth, rank} = props;
  const classes = useStyles(minwidth);
  const [data, setData] = useState(localData);
  const [isLoading, setLoading] = useState(false)
  
  useEffect(() => {
    if (!localData) {
      axios_set_data(url, setData, setLoading);
    }
  }, [])
  useEffect(() => {
    if (localData) {
      setData(localData);
    }
  })
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{borderRadius: "6px"}}>
        <EnhancedTableToolbar title={title} secondary_title={secondary_title} under_title={under_title}/>
        {localData && <>
          <TableContainer style={{borderRadius: "6px"}} className={classes.tableContainer} component={Paper} width='100%'>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='small'
              aria-label="enhanced table"
              stickyHeader
            >
              <EnhancedTableHead headLabels = {headLabels} rank = {rank} expander = {expander}/>
              <TableBody>
                {
                data.map((row, index) => (
                  <RowGenerator 
                    key = {"row"+index}
                    index = {index}
                    row = {row}
                    dataProcessors = {dataProcessors}
                    rank = {rank}
                    expander = {expander}
                  />
                ))                
                }
                {localData2 &&
                localData2.map((row, index) => (
                  <RowGenerator 
                    key = {"row"+index}
                    index = {index}
                    row = {row}
                    dataProcessors = {dataProcessors}
                    rank = {rank}
                    expander = {expander}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>}
        <LoadingBox isLoading = {isLoading}/>
      </Paper>
    </div>
  );
}