// From tutorial
import { Container, Divider, AppBar, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import SearchBar from 'material-ui-search-bar';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDebounce } from '../../hooks/debounceHook';
import { axios_set_data } from '../../config';
import OptionBox from './OptionBox';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core';
import LoadingBox from '../LoadingBox';
// import { useEffect } from 'react';
// import { useClickOutside } from "react-click-outside-hook";
// import { grey } from '@material-ui/core/colors';
// import ClearIcon from '@material-ui/icons/Clear';


const containerVariants = {
  expanded: {
    height: "20em",
  },
  collapsed: {
    height: "3em",
  }
}

const SearchBarContainer = styled(motion.div)({
  display: "flex",
  flexDirection: 'column',
  backgroundColor : '#fff',
  borderRadius: '6px',
  boxShadow: '0px 2px 12px 3px rgba(0, 0, 0, 0.18)',
  padding: '0px 0px',
  '&:hover': {
    boxShadow: '0px 2px 12px 3px rgba(0, 0, 0, 0.30)',
  },
  transition: "box-shadow .2s ease-in-out"
});

const SearchInputContainer = styled(Container)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  padding: '0px 0px'
});

const SearchInput = styled(SearchBar)({
  width: '100%',
  height: '100%',
  outline: 'none',
  border: 'none',
  fontSize: '21px',
  color: '#12112e',
  fontWeight: 500,
  backgroundColor: 'transparent',
  padding: '0px 0px 0px 0px',
  borderRadius: '6px',
  boxShadow: '0 0px 0px 0px rgba(0, 0, 0, 0)',
  '&:focus' :{
    outline: 'none',
    '&::placeholder': {
      opacity: 0
    }
  },

})

const containerTransition = { type: 'spring', damping: '22', stiffness: 150}

const LineSeperator = styled(Divider)({
  display: 'flex',
  minWidth: '100%',
  minHeight: '2px',
  backgroundColor: '#d8d8d878',
})
const LoadingWrapper = styled(Container)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: "#a1a1a1",
});

const SearchContent = styled(Container)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0em 1em 1em 1em',
  overflowY: "auto"
})

const useStyles = makeStyles((theme) => ({
  resultsDivider: {
  }
}));

const prepareSearchQuery = (type, query) => {
  const url = `/api_ranking/${type}/?search=${query}`
  return encodeURI(url);
}

export default function MainSearch(props) {
  let { theme, handlers } = props;
  if (!handlers){
    handlers = {
      addLeagueHandler: null,
      addTeamHandler: null,
      addRunnerHandler: null,
    }
  }
  const classes = useStyles(theme); 
  const [isExpanded, setExpanded] = useState(false);
  //const [parentRef, isClickedOutside] = useClickOutside();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoadingA, setLoadingA] = useState(false);
  const [isLoadingB, setLoadingB] = useState(false);
  const [isLoadingC, setLoadingC] = useState(false);
  const isLoading = isLoadingA || isLoadingB || isLoadingC;
  const [leagueOptions, setLeagueOptions] = useState(null);
  const [teamOptions, setTeamOptions] = useState(null);
  const [runnerOptions, setRunnerOptions] = useState(null);

  const runnerOptionsEmpty = !runnerOptions || runnerOptions.results.length === 0;
  const teamOptionsEmpty = !teamOptions || teamOptions.results.length === 0;
  const leagueOptionsEmpty = !leagueOptions || leagueOptions.results.length === 0;
  const isEmpty = runnerOptionsEmpty && teamOptionsEmpty && leagueOptionsEmpty


  // Container handling, for making the box get bigger or smaller when clicked
  const collapseContainer = () => {
    setExpanded(false);
    setRunnerOptions(null)
    setTeamOptions(null)
    setLeagueOptions(null)
    setSearchQuery("");
  }
  // useEffect(() => {
  //   if(isClickedOutside) collapseContainer();
  // }, [isClickedOutside])

  // Searching
  const searchRunnersTeamsLeagues = () => {
    if(!searchQuery || searchQuery.trim() === ""){
      setExpanded(false);
      return;
    }
    setExpanded(true);
    setRunnerOptions(null)
    setTeamOptions(null)
    setLeagueOptions(null)
    setLoadingA(true);
    setLoadingB(true);
    setLoadingC(true);
    const leaguesURL = prepareSearchQuery("search_leagues", searchQuery);
    const teamsURL = prepareSearchQuery("search_teams", searchQuery);
    const runnersURL = prepareSearchQuery("search_runners", searchQuery);
    axios_set_data(leaguesURL, setLeagueOptions, (setLoadingA));
    axios_set_data(teamsURL, setTeamOptions, setLoadingB);
    axios_set_data(runnersURL, setRunnerOptions, setLoadingC);
  }

  useDebounce(searchQuery, 500, searchRunnersTeamsLeagues)

  return (
    <Container maxWidth = 'sm'>
      <SearchBarContainer 
        animate={isExpanded ? "expanded" : "collapsed"} 
        variants={containerVariants}
        transition = {containerTransition}
        //ref = {parentRef}
      >
        <SearchInputContainer>
          <SearchInput 
            elevation = {0}
            placeholder = 'Search for a runner, team, or league...' 
            //onFocus = {expandContainer}
            value = {searchQuery}
            onChange = {(e) => {
              setSearchQuery(e);
            }}
            onCancelSearch = {collapseContainer}
            onRequestSearch = {() => setExpanded(true)}
          />
        </SearchInputContainer>
        {isExpanded && <LineSeperator/>}
        {isExpanded && 
        <SearchContent onClick={collapseContainer}>
          {!isLoading && isEmpty && (
            <LoadingWrapper >
              No runners, teams, or leagues found!
            </LoadingWrapper>
          )}
          {!leagueOptionsEmpty &&(
            <>
              <AppBar className={classes.resultsDivider} position="sticky" top="5px">
                <Typography variant="button" >
                  Leagues
                </Typography>
              </AppBar>
              <List component="nav">
                {leagueOptions.results.map((league, i) => (
                  <OptionBox
                    handler = { handlers.addLeagueHandler }
                    key = {"league" + i} 
                    object = { league }
                    details = {`(${league.detail_string})`}
                    type = {"leagues"}
                  />
                ))}
              </List>
            </>
          )}
          {!teamOptionsEmpty && (
            <>
              <AppBar className={classes.resultsDivider} position="sticky" top="5px">
                <Typography variant="button" >
                  Teams
                </Typography>
              </AppBar>
              <List component="nav">
                {teamOptions.results.map((team, i) => (
                  <OptionBox
                    handler = { handlers.addTeamHandler }
                    key = {"team" + i} 
                    object = { team }
                    details = {`(${team.sex})`}
                    type = {"teams"}
                  />
                ))}
              </List>
            </>
          )}
          {!runnerOptionsEmpty && (
            <>
              <AppBar className={classes.resultsDivider} position="sticky" top="5px">
                <Typography variant="button" >
                  Runners
                </Typography>
              </AppBar>
              <List component="nav">
                {runnerOptions.results.map((runner, i) => (
                  <OptionBox
                    handler = { handlers.addRunnerHandler }
                    key = {"runner" + i} 
                    name = {runner.name} 
                    object = { runner }
                    details = {runner.team?`(${runner.team.name})`:''}
                    type = {"runners"}
                    id = {runner.id}
                  />
                ))}
              </List>
            </>
          )}
          <LoadingBox isLoading = {isLoading}/>
        </SearchContent>}
      </SearchBarContainer>
    </Container>
  )
}