import { Container, Typography, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Fragment } from "react";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px',
    margin: '5px',
    flexGrow: 1,
    textAlign: 'left',
  },
  maintitle: {
    textAlign: 'center',
    color: "text.primary"
  },
  paragraph: {
    color: "text.primary",
  },
  subtitle: {
    color: "textPrimary",
  }
}));

const QA = (props) => {
  const {question, answer} = props
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Typography className={classes.subtitle} variant = "body1" gutterBottom color = "textPrimary">
      <Box fontWeight="fontWeightBold">
        {question}
      </Box>
      </Typography>
      <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
        {answer}
      </Typography>
    </Grid>
  )
}

const FAQ = () => {
  const classes = useStyles();
  return (
    <Container className="faq" >
      <Typography variant="h4" className={classes.maintitle} gutterBottom color = "textPrimary">Frequently Asked Questions</Typography>
      <Grid className={classes.root} container spacing={2}>
        <QA 
          question = 'Does LACCTiC take into account altitude or short/long courses?'
          answer = {
          <Fragment>
            <Typography>
              Yes - this is precisely the point of the website. 
              When times are slower/faster because of altitude, course length, hills or weather, all runners are affected equally (more or less).
              This means that we can look at the median difference in times between courses to figure out a relative conversion.
              LACCTiC does this on a large scale, comparing all possible runners and courses. 
            </Typography>
            <Typography>
              The only factors LACCTiC cannot pick up on are ones that affect runners differently.
              Maybe some runners are more acclimatized to the heat or elevation? Maybe some runners are better at hills?
              Maybe there was a fall that only affected part of the field? These are all factors that will result in better/worse scores for individuals.
            </Typography>
          </Fragment>
          }
        />
        <QA 
          question = "How are team rosters determined?"
          answer = {
          <Typography>
            1. Anyone who has raced this season  &rarr; <span style={{color : green[500]}}>Active</span> <br/>
            2. On TFRRS roster but has not yet raced  &rarr; <span style={{color : red[500]}}>Inactive</span> <br/>
            3. Senior in previous year and not on a TFRRS roster  &rarr; <span style={{color : red[500]}}>Graduated</span> and <span style={{color : red[500]}}>Inactive</span> <br/>
            4. Any runner on a roster who has not yet competed &rarr; Not yet in LACCTiC until they race.<br/>
            <span style={{color : red[500]}}>Graduated</span> runners do not appear in team rosters. <br/>
            <span style={{color : red[500]}}>Inactive</span> runners do appear in team rosters.

          </Typography>
          }
        />
        <QA 
          question = "How are weightings determined?"
          answer = {
          <Fragment>
            <Typography>
             Starting in 2022, LACCTiC began using an updated weigthing algorithm to promote simplicity. 
             Fitness estimates are based on two performance: (1) The runner's top performance this season (2) The runner's top performance amoung "important" races. 
             Track and previous season fitnesses are also included if you have not yet surpassed them this season, or if you
             have less than two performances.
            </Typography>
            <Typography>
             The relative weights of the chosen performances are calculated based an estimate of the race's importance
            </Typography>
          </Fragment>
          }
        />
        <QA 
          question = "Why are some runners marked as inactive?"
          answer = {
          <Fragment>
            <Typography>
              Runners that have not yet run this season are marked as inactive. Inactive runners who were marked as seniors last year are marked as graduated.
            </Typography>
          </Fragment>
          }
        />
        <QA 
          question = "How often is the data updated?"
          answer = {
          <Fragment>
            <Typography>
              I will update the data with new meets every weekend. Scores for other meets this season will be adjusted as additional
              data comes in. Scores for meets in past seasons will remain the same.
            </Typography>
          </Fragment>
          }
        />
        <QA 
          question = 'Why are some races missing or "unstable"?'
          answer = {
          <Fragment>
            <Typography>
              Races will not be included until there is sufficient data to score them. For example, a race with only first-years
              cannot be compared until thoes runners compete against others.
            </Typography>
          </Fragment>
          }
        />
        <QA 
          question = "Can I use your data for a school project?"
          answer = {
          <Fragment>
            <Typography>
              Yes! A number of students have inquired about using LACCTiC for school projects.
              If you reach out to me, I can give you access to the API (an API is another website
               that gives direct access to the data without any need for scraping).
            </Typography>
          </Fragment>
          }
        />
      </Grid>
    </Container>
  )
}

export default FAQ;