import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { prettyTime, axios_set_data, RunnerLACCTiC, shortDate } from '../config';
import UnpaginatedTable from '../components/UnpaginatedTable';
import tfrrsLogo from '../img/TFRRS.png';
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga4';

//ReactGA.initialize("G-YLC6GCTSVK'");
//const TRACKING_ID = "UA-206649060-1";
//ReactGA.initialize(TRACKING_ID);

const useStyles = makeStyles((theme) => ({  
  root: {
    padding: '0px',
    margin: '10px',
    flexGrow: 1,
    textAlign: 'left',
  },
  maintitle: {
    textAlign: 'center'
  },
  primary: {
    textAlign: 'left',
  },
  secondary: {
    textAlign: 'right',
  },
  table: {
    minWidth: 650,
    marginTop: "0",
    marginBottom: "10",
    padding: "0",
  },
  tableContainer: {
    maxHeight: 300,
    backgroundColor: "white"
  },
  infoGrid: {
    margin: "0 0 40 0",
    padding: "20 20 20 20"
  },
  range: {
    color: "gray"
  },
}));

function Runner(props) {
  const { id } = useParams();
  const [main, setMain] = useState(null);
  const { theme } = props;
  const classes = useStyles(theme); 
  const d = new Date();
  const currentYear = d.getFullYear();

  const trackOrxcHeadLabels = [
    {label: "TiC", info: `A 5k equivalent.`}, 
    {label: "Time"}, 
    {label: "Date"}, 
    {label: "Race"},
    {label: "Importance", info: "Importance distinguishes big inter-regional races from smaller local ones. 10 is the maximum score for the season so far (the score may decrease with time)."}, 
    {label: "Season Weight", info: "The weighting used to calculate the runner's seasonal LACCTiC rating."}]
  
  const trackOrXCDataProcessors = [
    (trackxc) => prettyTime(Math.exp(trackxc.modern_tic)),
    (trackxc) => prettyTime(trackxc.time),
    (trackxc) => trackxc.date ? shortDate(trackxc.date) : shortDate(trackxc.race.date),
    (trackxc) => trackxc.meet_name ? (<Link target="_blank" to={{pathname : trackxc.url}}>{`${trackxc.meet_name} - ${trackxc.race.section} Track`}</Link>) : (<Link to = {`/races/${trackxc.race.id}`}>{trackxc.race.meet_name}</Link>),
    (trackxc) => Math.round(trackxc.race.importance*10)/10,
    (trackxc) => `${Math.round(trackxc.race_weight_sig * 100)}%`
  ];

  function setMainAndSort(data_to_set){
    data_to_set.season_ratings.sort((a, b) => b.season.year-a.season.year);
    setMain(data_to_set);
    return null;
  }
  useEffect(() => {
    const mainURL = encodeURI(`/api_ranking/runner_page/${id}`);
    ReactGA.event({
      category: 'Explore',
      action: 'Viewed runner page'
    });
    axios_set_data(mainURL, setMainAndSort, (x) => {});
  }, [])

  return (
    <Container className={classes.root}>
      <Grid className = {classes.infogrid} container spacing={5}>
        {main && (
          <>
            <Grid item xs={7} className={classes.primary}>
              <Typography variant="h4" component="h4" color = "textPrimary">
                {main.firstname + " " + main.lastname}
              </Typography>
              <Typography variant = "h6" color = "textSecondary" gutterBottom>
                {main.team && (<Link color="secondary" to = {`/teams/${main.team.id}`}> {main.team.name}&nbsp;({main.year_in_school}) </Link>)}
              </Typography>
              <Typography variant = "h6" color = "textPrimary" align="left">
                  LACCTiC Rating:&nbsp;<RunnerLACCTiC runner={main} variant = "h6"/>
              </Typography>
            </Grid>
            <Grid item xs={5} className={classes.secondary}>
              <Button variant="contained" component="a" href = {`https://www.tfrrs.org/athletes/${main.tfrrs_id}.html`}>
                <img  src={tfrrsLogo} alt="TFRRS" width="130"></img>
              </Button>
              <Typography variant = "h6" color = "textSecondary" gutterBottom>
                Status: {main.status}
              </Typography>
            </Grid>
          </>
        )}
        {main && main.season_ratings.map((sr) => (
          <Grid item xs={12} key={sr.season.year}>
            <UnpaginatedTable
            localData = { sr.season_xc_performances} 
            localData2 = {sr.season_track_performances}
            separator = "Track"
            title = {`${sr.season.year} Season`}
            under_title = {`Season Fitness Estimate: ${prettyTime(Math.exp(sr.sig_tic))}`}
            secondary_title = {`Weight: ${Math.round(100 * sr.race_weight_sig * sr.significant)}%`}
            headLabels = { trackOrxcHeadLabels } 
            dataProcessors = { trackOrXCDataProcessors } 
            minwidth={550}
            />
          </Grid>
        ))}
          

      </Grid>
    </Container>
  );
}

export default Runner;
