import './App.css';
import Header from './components/Header';
import Footer from './components/Footer'
import Home from './pages/Home';
import Race from './pages/Race';
import Container from '@material-ui/core/Container';
import About from './pages/About';
import Tutorial from './pages/Tutorial';
import Contact from './pages/Contact';
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { lightBlue, grey } from '@material-ui/core/colors'
import 'fontsource-roboto'
import Runner from './pages/Runner'
import Team from './pages/Team'
import League from './pages/League';
import Simulate from './pages/Simulate';
import Donate from './pages/Donate';
import FAQ from './pages/FAQ';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import RouteChangeTracker from './components/RouteChangeTracker';


const theme = createTheme({
  palette: {
    primary: {
      main: grey[800]
    },
    secondary: {
      main: lightBlue[400]
    },
    textPrimary:{
      main: grey[900]
    },
    textSecondary:{
      main: grey[300]
    }
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 14,
  },
  MuiLink: {
    // Name of the rule
    color: "secondary",
    underline: "none"
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 25,
      }, 
    }, 
  },
})

function App() {
  // const pageViewsTracking = (props) => {
  //   const pathname = props.match.path;

  //   let pageView;
  //   if (pathname === "*") pageView = "/not-found";
  //   else pageView = pathname;
  //   ReactGA.pageview(pageView);
  return (
    <Router>
      <RouteChangeTracker/>
      <ThemeProvider theme={ theme }>
        <div className="App">
          <Header/>
          <div className="content">
            <Container maxWidth = "md">
              <Switch>
                <Route path="/about">
                  <About/>
                </Route>
                <Route path="/contact">
                  <Contact/>
                </Route>
                <Route path="/donate">
                  <Donate/>
                </Route>
                <Route path="/faq">
                  <FAQ/>
                </Route>
                <Route path="/runners/:id">
                  <Runner/>
                </Route>
                <Route path="/teams/:id">
                  <Team/>
                </Route>
                <Route path="/leagues/:id">
                  <League/>
                </Route>
                <Route path="/races/:id">
                  <Race/>
                </Route>
                <Route path ="/simulate/:preloaded?">
                  <Simulate />
                </Route>
                <Route path ="/tutorial">
                  <Tutorial />
                </Route>
                <Route path ="/">
                  <Home />
                </Route>
              </Switch>
            </Container>
          </div>
          <Footer/>
        </div>
      </ThemeProvider>
    </Router>
  );
}

export default App;
