import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Container } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useState, useEffect, Fragment } from 'react';
import UnpaginatedTable from '../components/UnpaginatedTable';
import { Typography } from '@material-ui/core';
import { axios_set_data, RunnerLACCTiC, prettyTime, shortDate } from '../config';
import tfrrsLogo from '../img/TFRRS.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { RunnerLACCTiCNoRange } from '../config';
import { RelativePlace, RelativeScore, RelativeTime } from '../components/RelativeToPredicted';
import ReactGA from 'react-ga4';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    margin: '10px',
    flexGrow: 1,
    textAlign: 'left',
  },
  primary: {
    textAlign: 'left',
  },
  secondary: {
    textAlign: 'right',
  },
  table: {
    minWidth: 450,
    marginTop: "0",
    marginBottom: "10",
    padding: "0",
  },
  tableContainer: {
    maxHeight: 300,
    backgroundColor: "white"
  },
  infoGrid: {
    margin: "0 0 40 0",
    padding: "20 20 20 20"
  },
  label: {
    fontWeight:"fontWeightMedium"
  },
  range: {
    color: "gray"
  },
}));

function scoreHypotheticalRunner(runner, results){
  let runner_tic_time = runner.ability;
  let biggest_score_seen = 0;
  results.forEach(result => {
    if (result.modern_tic > runner_tic_time){
      return biggest_score_seen + 1;
    }
    if (result.runner.score){
      biggest_score_seen = result.runner.score;
    }
  })
  return biggest_score_seen + 1;
}

function filterTeams(teams, results) {
  teams.forEach(team => {
    team.runners_finished = 0;
  });
  let filtered_teams = [];
  results.forEach((result => {
    const team = teams.find(team => team.id === result.runner.team.id);
    if (team) {
      team.runners_finished += 1;
    }
  }));
  teams.forEach(team => {
    if (team.runners_finished >= 5){
      filtered_teams.push(team);
    }
  });
  return filtered_teams;
}

function ScoredTeams(teams, results) {
  let scoreCounter = 1;
  // Initialize
  teams.forEach(team => {
    team.participating_runners = []
    team.participating_runnerIDs = []
    team.score = 0
  })
  // Score the results
  results.forEach((result => {
    const team = teams.find(team => team.id === result.runner.team.id);
    if (team) {
      const runner = result.runner;
      runner.score = scoreCounter;
      team.participating_runners.push(runner);
      team.participating_runnerIDs.push(runner.id);
      if (team.participating_runners.length <= 5){
        team.score += scoreCounter;
      }
      if (team.participating_runners.length <= 7){
        scoreCounter++;
      }
    }
  }))
  // Sort so that results are in order
  teams.sort((a, b) => a.score - b.score);
  // Now look at each team and find runners who are not racing
  teams.forEach(team => {
    team.nonparticipating_runners = team.roster.filter(runner => (
      !team.participating_runnerIDs.includes(runner.id)) 
      && (runner.year_in_school !== "GR"))
    // Find hypothetical score
    team.nonparticipating_runners.forEach( runner => {
      runner.hypothetical_score = scoreHypotheticalRunner(runner, results);
    })
    //const hyp_scores = team.nonparticipating_runners.map(runner => runner.hypothetical_score);
    //const real_scores = team.participating_runners.map(runner => runner.score);
    //let total_scores = hyp_scores.concat(real_scores);
    //total_scores.sort((a, b) => a-b);
    //team.hypothetical_score = total_scores.slice(0, 4).reduce((accumulator, curr) => accumulator + curr);
  })
  return teams;
}

function PredictedScoredTeams(teams, results) {
  let results_copy = [... results];
  results_copy.sort((a, b) => {
    if (!a.predicted_place) {
      return 1;
    }
    if (!b.predicted_place) {
      return -1;
    }
    return a.predicted_place - b.predicted_place;
  })
  let scoreCounter = 1;
  teams.forEach(team => {
    team.predicted_score = 0;
    team.runners_finished = 0;
  })
  results_copy.forEach((result => {
    const team = teams.find(team => team.id === result.runner.team.id);
    if (team) {
      team.runners_finished += 1;
      if (team.runners_finished <= 5){
        team.predicted_score += scoreCounter;
      }
      if (team.runners_finished <= 7){
        scoreCounter++;
      }
    }
  }))
  let teams_copy = [...teams];
  teams_copy.sort((a, b) => a.predicted_score - b.predicted_score);
  let placeCounter = 1;
  teams_copy.forEach(team => {
    team.predicted_place = placeCounter;
    placeCounter++;
  })
  return teams
}

function isDateBeforeMillisecondCorrection(dateString){
  const threshholdDate = new Date(Date.UTC(2021, 9, 1));
  let b = dateString.split(/\D+/);
  const date =  new Date(Date.UTC(b[0], --b[1], b[2]));
  return date <= threshholdDate;
}

function Race(props) {
  const { id } = useParams();
  const [raceData, setRaceData] = useState(null);
  const { theme } = props;
  const classes = useStyles(theme); 
  useEffect(() => {
    const mainURL = encodeURI(`/api_ranking/race_page/${id}`);
    axios_set_data(mainURL, setRaceData, (x) => {});
    ReactGA.event({
      category: 'Explore',
      action: 'Viewed race page'
    });
  }, [])
  const year = raceData? raceData.date.split(/\D+/)[0]:"";
  // Individual table stuff
  const resultsHeadLabels = [
    {label: "Place", info: "The colored numbers show how performances changed relative to what LACCTiC expected before the race was added."},
    {label: "Name"}, 
    {label: "Time"},
    {label: "TiC", info: "A 5k equivalent for this performance."}, 
    {label: "LACCTiC Rating", info: "A current estimate of the runner's 5k fitness."}, 
    {label: "Year"},
    {label: "Team"}];

  const resultsDataProcessors = [
    (xc) => (<RelativePlace actual = {xc.place} predicted = {xc.predicted_place}/>),
    (xc) => (<Link to={`/runners/${xc.runner.id}`}>{xc.runner.firstname+ " " + xc.runner.lastname}</Link>),
    (xc) => prettyTime(xc.time),
    (xc) => (<RelativeTime actual = {Math.exp(xc.modern_tic)} predicted = {null}/>),
    (xc) => (<RunnerLACCTiC runner={xc.runner}/>),
    (xc) => xc.runner.year_in_school,
    (xc) => xc.runner.team && <Link to={`/teams/${xc.runner.team.id}`}>{xc.runner.team.name}</Link>,
  ];

  // Team table stuff
  let teams = raceData && raceData.participating_teams;
  let results = raceData && raceData.xc_results;
  teams = teams && filterTeams(teams, results)

  let scoredTeams = teams && ScoredTeams(teams, results);
  scoredTeams = scoredTeams && PredictedScoredTeams(scoredTeams, results);
  
  // Set places from order
  let placeCounter = 1;
  scoredTeams && scoredTeams.forEach(team => {
    team.place = placeCounter;
    placeCounter++;
  });
  placeCounter = 1;
  results && results.forEach(result => {
    result.place = placeCounter;
    placeCounter++;
  });
  const teamHeadLabels = [
    {label: "Place", info: "The colored numbers show how performances changed relative to what LACCTiC expected before the race was added."},
    {label:"Team"}, 
    {label:"Score", info: "The colored numbers show how performances changed relative to what LACCTiC expected before the race was added."}];
  const teamDataProcessors = [
    (team) => (<RelativePlace actual = {team.place} predicted = {team.predicted_place}/>),
    (team) => (<Link to={`/teams/${team.id}`}>{team.name}</Link>),
    (team) => (<RelativeScore actual={team.score} predicted = {team.predicted_score}/>)
  ];
  const teamExpander = (team) => {
    return(
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Table size="small" aria-label="ran">
            <TableHead>
              <TableRow>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Competing Runners</Box></Typography></TableCell>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Rating</Box></Typography></TableCell>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Score</Box></Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {team.participating_runners.map((runner) => (
                <TableRow key={runner.firstname + runner.lastname}>
                  <TableCell>
                    <Typography style={{fontSize:"12px"}}><Link to={`/runners/${runner.id}`}>{runner.firstname+ " " + runner.lastname}</Link></Typography>
                  </TableCell>
                  <TableCell><Typography style={{fontSize:"12px"}}><RunnerLACCTiCNoRange runner = {runner}/></Typography></TableCell>
                  <TableCell>
                    <Typography style={{fontSize:"12px"}}>{runner.score}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Table size="small" aria-label="didntrun">
            <TableHead>
              <TableRow>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Noncompeting Runners</Box></Typography></TableCell>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Rating</Box></Typography></TableCell>
                <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >Hypothetical Score</Box></Typography></TableCell>
                <TableCell><Box fontWeight="fontWeightBold" >Status</Box></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {team.nonparticipating_runners.map((runner) => (
                <TableRow key={runner.firstname + runner.lastname}>
                  <TableCell>
                    <Typography style={{fontSize:"12px"}}><Link to={`/runners/${runner.id}`}>{runner.firstname+ " " + runner.lastname}</Link></Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{fontSize:"12px"}}><RunnerLACCTiCNoRange runner = {runner}/></Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{fontSize:"12px"}}>{runner.hypothetical_score}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{fontSize:"12px"}}>{runner.status}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    )
  }
  return (
    <Container className={classes.root}>
      <Grid className = {classes.infogrid} container spacing={2}>
        {raceData && (
          <>
            <Grid item xs={6} className={classes.primary}>
              <Typography variant="h5" color = "textPrimary">
               {raceData.meet_name} - {raceData.section} ({shortDate(raceData.date)})
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.secondary}>
              <Button variant="contained" component="a" href = {raceData.tfrrs_url}>
                <img alt = "TFRRS" src={tfrrsLogo} width="130"></img>
              </Button>
            </Grid>
            <Grid item xs={12}>
            {raceData.sex === "M" && 
              <Fragment>
                <Typography color = "textPrimary" align="left">
                  Race Importance: {Math.round(raceData.importance*10)/10}/10
                </Typography>
                <Typography color = "textPrimary" align="left">
                  14:00 TiC = {prettyTime(Math.exp(Math.log(14 * 60) + raceData.score))}
                </Typography>
                <Typography color = "textPrimary" align="left">
                  15:00 TiC = {prettyTime(Math.exp(Math.log(15 * 60) + raceData.score))}
                </Typography>
                <Typography color = "textPrimary" align="left">
                  16:00 TiC = {prettyTime(Math.exp(Math.log(16 * 60) + raceData.score))}
                </Typography>
              </Fragment>}
              {raceData.sex === "W" && 
              <Fragment>
                <Typography color = "textPrimary" align="left">
                  16:00 TiC = {prettyTime(Math.exp(Math.log(16 * 60) + raceData.score))}
                </Typography>
                <Typography color = "textPrimary" align="left">
                  17:00 TiC = {prettyTime(Math.exp(Math.log(17 * 60) + raceData.score))}
                </Typography>
                <Typography color = "textPrimary" align="left">
                  18:00 TiC = {prettyTime(Math.exp(Math.log(18 * 60) + raceData.score))}
                </Typography>
              </Fragment>}
              <Typography>
                  When LACCTiC gets data for a new race, it takes the list of competitors and simulates the race before doing any analysis and stores its predictions.
                  The red and green numbers report how the teams and runners performed relative to these predictions.
              </Typography>
            </Grid>
          </>
        )}
        {raceData && isDateBeforeMillisecondCorrection(raceData.date) && 
          (<Typography>Note: Scoring of older races may not be accurate because I did not store fractions of a second before 10/2.</Typography>)
        }
        {raceData && 
        <Grid item xs={12}>
          <UnpaginatedTable
            title = {"Team Results"}
            localData = { scoredTeams }
            headLabels = { teamHeadLabels } 
            dataProcessors = { teamDataProcessors } 
            minwidth={550}
            rank = {false}
            expander = {teamExpander}
          />
        </Grid>}
        {raceData && 
        <Grid item xs={12}>
          <UnpaginatedTable
            title = {raceData.section}
            localData = { raceData.xc_results }
            headLabels = { resultsHeadLabels } 
            dataProcessors = { resultsDataProcessors } 
            minwidth={550}
            rank = {false}
          />
        </Grid>}
      </Grid>
    </Container>
  );
}

export default Race;
