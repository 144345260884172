import { Container, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px',
    margin: '5px',
    flexGrow: 1,
    textAlign: 'left',
  },
  maintitle: {
    textAlign: 'center',
    color: "text.primary"
  },
  paragraph: {
    color: "text.primary",
  },
  subtitle: {
    color: "textPrimary",
  }
}));

const Tutorial = () => {
  const classes = useStyles();
  return (
    <Container className="tut" >
      <Typography variant="h4" className={classes.maintitle} gutterBottom color = "textPrimary">Intro to LACCTiC</Typography>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs = {12}>
          <Typography variant = "body1" gutterBottom color = "textPrimary">
            This page will help you get started. LACCTiC has a few types of pages:
          </Typography>
        </Grid>
        <Grid item xs = {12}>
          <Typography className={classes.subtitle} variant = "h6" gutterBottom color = "textPrimary"> Home Page </Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
            This page lets you search for a runner, team, or league. It also has quick links to the 3 NCAA Divisions + NAIA and their regions. 
            Click the down arrow next to a division to get links to its regions. If you scroll farther down, you also get a list of all the races
            LACCTiC has analyzed, which you can page through in reverse-chronological order.
          </Typography>
        </Grid>
        <Grid item xs = {12}>
          <Typography className={classes.subtitle} variant = "h6" gutterBottom color = "textPrimary"> Runner Pages </Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
            Cross country performances are converted to track 5k equivalents and listed here with links to the race pages.
            I do this for both the most recent track season and the season preceeding the performance. 
            There was around a 1.5% difference between the conversions for 2020 and 2021 (corresponds to around 13 seconds for a 14:30 5ker).
          </Typography>
        </Grid>
        <Grid item xs = {12}>
          <Typography className={classes.subtitle} variant = "h6" gutterBottom color = "textPrimary"> Team Pages </Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
            These pages show the team rosters with ability estimates for each runner. 
            Runners who have not competed this season are marked as inactive.
            At the top right of the page is a list of links to leagues that the team belongs to.
            At the bottom of the page is a list of races that the team has participated in.
          </Typography>
        </Grid>
        <Grid item xs = {12}>
          <Typography className={classes.subtitle} variant = "h6" gutterBottom color = "textPrimary"> Race Pages </Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
          You can see in green/red arrows how teams and individuals performed relative to the website's prediction. 
          Also, if you go to the "team" part and click the down-arrow you can see who did and did not compete for the team and how the non-competitors would have scored in the race. 
          This makes it easy to evaluate what is an upset and what is just the result of a partial squad.
          </Typography>
        </Grid>
        <Grid item xs = {12}>
          <Typography className={classes.subtitle} variant = "h6" gutterBottom color = "textPrimary">Simulations</Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
          You can pre-load a league by clicking the blue "Simulate/Rank" button on a league page. Or you can start a blank one by clicking "Start a new simulation" on the home page. Teams and runners can be added with the search bar and removed by clicking on the trash icons.
          When you are happy with the field, click "simulate" and the computer will run 1000 simulations of the race. You can expand the results by clicking on the arrows in the left of the table, which will give you histograms of each team/runners finishes.
          </Typography>
          <Typography className={classes.paragraph} variant = "body1" gutterBottom color = "textPrimary">
          I think simulations are the correct way to do rankings/previews. 
          The field that a team is going to race in is everything. 
          Sometimes we think there is an upset, but we really just underestimated how costly that 5th runner was in the new field.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Tutorial;