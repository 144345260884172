
import axios from 'axios';
import { makeStyles } from '@material-ui/core';
import { Fragment } from 'react';


// API fetching methods
//export const API_URL = "https://34brw32tj5.execute-api.us-east-2.amazonaws.com/testing";
export const API_URL = "https://c03mmwsf5i.execute-api.us-east-2.amazonaws.com/production";
//export const API_URL = "http://127.0.0.1:8000";


export function axios_set_data(url, setData, setLoading) {
  const fullURL = API_URL + url;
  let data;
  setLoading( true );
  axios.get( fullURL ).catch(( err ) => {
    console.log("Error", err);
  }).then(( response ) => {
    data = response.data;
    setData( data );
    setLoading( false );
  })
  return data;
}

export function axios_get_data(url) {
  const fullURL = API_URL + url;
  return axios.get( fullURL ).catch(( err ) => {
    console.log("Error", err);
  })
}


const useStyles = makeStyles((theme) => ({
  range: {
    color: "gray"
  },
}));

export function shortDate(dateString){
  let b = dateString.split(/\D+/);
  const date =  new Date(Date.UTC(b[0], --b[1], ++b[2], -12));
  return date.toLocaleDateString('en-US', {day: "numeric", month:"numeric", year:"2-digit"})
}

// Stuff for string formatting
export function prettyTime( secs ) {
  if (secs <= 1){
    return "UNSTABLE";
  }
  var days, hours, minutes, seconds;
  if( secs == null ) return "";
  days = ( secs / 86400 ) >> 0;
  hours = ( secs % 86400 / 3600 ) >> 0;
  minutes = ( secs % 3600 / 60 ) >> 0;
  seconds = ( secs % 60 ) >> 0;    
  seconds = seconds < 10 ? "0" + seconds : seconds;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  hours = hours && hours < 10 ? "0" + hours : hours;
  
  return "" + ( days ? days+" - " : "" ) + ( hours ? hours+":" : "" ) + minutes + ":" + seconds;      
}

export function PrettyRange( props ) {
  let {lower, upper, theme } = props;
  const classes = useStyles(theme); 
  lower = Math.round( lower );
  upper = Math.round( upper );
  if (lower === upper) {
    return "";
  } else {
    return (<span className={classes.range}>{"(" + lower.toString() + "\u00a0-\u00a0" + upper.toString() + ")"}</span>);
  }
}

function PrettyTimeRange( props ) {
  let {lowerSecs, upperSecs, theme } = props;
  const classes = useStyles(theme); 
  lowerSecs = Math.round( lowerSecs );
  upperSecs = Math.round( upperSecs );
  if (lowerSecs === upperSecs) {
    return "";
  } else {
    return (<span className={classes.range}>{"(" + prettyTime( lowerSecs )+ "\u00a0-\u00a0" + prettyTime( upperSecs ) + ")"}</span>);
  }
}

export function PrettyValueRange(props){
  let {list_of_vals, avg } = props;
  avg = avg.toFixed(1);
  list_of_vals.sort((a, b) => a - b);
  let lower = list_of_vals[Math.round(.16 * list_of_vals.length) - 1]
  let upper = list_of_vals[Math.round(.84 * list_of_vals.length) - 1]
  return (
    <Fragment>
      {avg} <PrettyRange lower= {lower} upper= {upper}/>
    </Fragment>
  )
}

export function PrettyTimeAbilityRange( props ){
  let {avg, std} = props
  const lowerSecs = Math.exp( avg - std );
  const upperSecs = Math.exp( avg + std );
  return <PrettyTimeRange lowerSecs={lowerSecs} upperSecs={upperSecs} />;
}

export function RunnerLACCTiC( props ){
  let {runner} = props; 
  return (
    <Fragment>
      {prettyTime(Math.exp(runner.ability))} <PrettyTimeAbilityRange avg = {runner.ability} std = {runner.ability_std} />
    </Fragment>
  )
}

export function RunnerLACCTiCNoRange( props ){
  let {runner} = props; 
  return (
    <Fragment>
      {prettyTime(Math.exp(runner.ability))}
    </Fragment>
  )
}

export function TeamLACCTiC( props ){
  let {team} = props; 
  return (
    <Fragment>
      {prettyTime(team.top_5_ability_average)} <PrettyTimeRange lowerSecs = {team.top_runner} upperSecs = {team.fifth_runner} />
    </Fragment>
  )
}