import { styled } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Fragment } from 'react';
import { Container } from '@material-ui/core';

const LoadingWrapper = styled(Container)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: "#a1a1a1",
  padding:"5px",
  margin:"5px"
});

function LoadingBox(props) {
  const { isLoading } = props;
  return (
    <Fragment>
      {isLoading && 
      <LoadingWrapper>
        <CircularProgress color="primary" size={20} />
      </LoadingWrapper>
      }
    </Fragment>
  )
}

export default LoadingBox;