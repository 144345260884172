import { Typography } from "@material-ui/core";

const Contact = () => {
  return (
    <div className="contact">
      <Typography variant="h6">Contact me</Typography>
      <Typography variant="body1">For emails realated to this site, please use: bijanmazaherilacctic (at) gmail (dot) com.</Typography>
      <Typography variant="body1">My instagram and twitter are both @honey_bijan. Feel free to tweet at or DM me.</Typography>
    </div>
  )
}

export default Contact;