import { Grid } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  primary: {
    color: "black",
    textAlign: "left"
  },
  secondary: {
    color: "#aaa",
    textAlign: "left"
  },
}));

export default function OptionBox(props) {
  const {object, details, type, handler} = props;
  let url = `/${type.toLowerCase()}/${object.id}`
  const classes = useStyles();
  if (handler) {
    return(
      <ListItem button divider onClick={() => handler(object)} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={4} className={classes.primary}>
          {type!="runners" && object.name}{type==="runners" && (object.firstname + " " + object.lastname)}
        </Grid>
        <Grid item xs={8} className={classes.secondary}>
          {details}
        </Grid>
      </Grid>
    </ListItem>
    )
  }
  return(
    <ListItem component={Link} button divider to={url} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={4} className={classes.primary}>
          {type!="runners" && object.name}{type==="runners" && object.firstname + " " + object.lastname}
        </Grid>
        <Grid item xs={8} className={classes.secondary}>
          {details}
        </Grid>
      </Grid>
    </ListItem>
  )
}