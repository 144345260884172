import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import UnpaginatedTable from '../components/UnpaginatedTable';
import { Typography } from '@material-ui/core';
import { axios_set_data, TeamLACCTiC, RunnerLACCTiC, shortDate } from '../config';
import tfrrsLogo from '../img/TFRRS.png';
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga4';
import LeagueList from '../components/LeagueList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    margin: '10px',
    flexGrow: 1,
    textAlign: 'left',
  },
  primary: {
    textAlign: 'left',
  },
  secondary: {
    textAlign: 'right',
  },
  table: {
    minWidth: 450,
    marginTop: "0",
    marginBottom: "10",
    padding: "0",
  },
  tableContainer: {
    maxHeight: 300,
    backgroundColor: "white"
  },
  infoGrid: {
    margin: "0 0 40 0",
    padding: "20 20 20 20"
  },
  label: {
    fontWeight:"fontWeightMedium"
  },
  range: {
    color: "gray"
  },
}));

function Team(props) {
  const { id } = useParams();
  const [teamData, setTeamData] = useState(null);
  const { theme } = props;
  const classes = useStyles(theme); 

  useEffect(() => {
    const mainURL = encodeURI(`/api_ranking/team_page/${id}`);
    axios_set_data(mainURL, setTeamData, (x) => {});
    ReactGA.event({
      category: 'Explore',
      action: 'Viewed team page'
    });
  }, [])

  const rosterHeadLabels = [
    {label: "Name"}, 
    {label: "LACCTiC Rating", info: "An estimate of the runner's 5k fitness."}, 
    {label: "Year"}, 
    {label: "Status", info: "If a runner does not compete for a long time, their status will go inactive."}];

  const rosterDataProcessors = [
    (runner) => (<Link to={`/runners/${runner.id}`}>{runner.firstname+ " " + runner.lastname}</Link>),
    (runner) => (<RunnerLACCTiC runner={runner}/>),
    (runner) => runner.year_in_school,
    (runner) => runner.status
  ];

  const raceHeadLabels = [
    {label: "Date"}, 
    {label: "Race"}];

  const raceDataProcessors = [
      (race) => (shortDate(race.date)),
      (race) => (<Link to = {`/races/${race.id}`}>{race.meet_name + " - " +race.section}</Link>),
    ];
  return (
    <Container className={classes.root}>
      <Grid className = {classes.infogrid} container spacing={5}>
        {teamData && (
          <>
            <Grid item xs={6} className={classes.primary}>
              <Typography variant="h4" component="h4" color = "textPrimary">
               {teamData.name}
              </Typography>
              <Typography variant = "h6" color = "textPrimary" align="left">
                Top 5 Avg Rating: <TeamLACCTiC team={teamData} variant = "h6"/>
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.secondary}>
              <LeagueList leagues={teamData.leagues}/>
              <Button variant="contained" component="a" href = {`https://www.tfrrs.org/teams/${teamData.tfrrs_id}.html`}>
                <img alt = "TFRRS" src={tfrrsLogo} width="130"></img>
              </Button>
            </Grid>
          </>
        )}
        {teamData && <Grid item xs={12}>
          <UnpaginatedTable
            localData = { teamData.roster } 
            title = "Roster" 
            headLabels = { rosterHeadLabels } 
            dataProcessors = { rosterDataProcessors } 
            minwidth={550}
            rank = {true}
          />
        </Grid>}
        {teamData && <Grid item xs={12}>
          <UnpaginatedTable
            title = "Recent Races"
            localData = { teamData.races }
            headLabels = { raceHeadLabels } 
            dataProcessors = { raceDataProcessors } 
            minwidth={550}
            rank = {false}
          />
        </Grid>}
      </Grid>
    </Container>
  );
}

export default Team;
