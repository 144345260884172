import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { axios_set_data } from '../config';
import { useState, useEffect } from 'react';
import { grey } from '@material-ui/core/colors';
import { EnhancedTableHead } from './UnpaginatedTable';
import LoadingBox from './LoadingBox';

const useStyles = makeStyles((theme, minwidth) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: minwidth,
    backgroundColor: "white"
  },
  tableContainer: {
    maxHeight: 500,
    backgroundColor: grey[800]
  },
  tableheader:{
    whiteSpace: "nowrap",
    alignItems: 'center',
    display: 'flex',
  }
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: grey[800],
    color: "white",
    borderRadius: "6px 6px 0px 0px"
  },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
    </Toolbar>
  );
};

export default function PaginatedTable(props) {
  const {url, title, headLabels, dataProcessors, minwidth, rank, ranklabel} = props;
  const classes = useStyles(minwidth);
  const pageLength = 100;
  const [page, setPage] = useState(0);
  const [data, setData] = useState(null)
  const [isLoading, setLoading] = useState(false)
  
  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    const newPageURL = url + `&page=${newPage + 1}`
    console.log(newPageURL);
    axios_set_data(newPageURL, setData, setLoading);
    setPage(newPage);
  };

  useEffect(() => {
    axios_set_data(url, setData, setLoading);
  }, [])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{borderRadius: "6px"}}>
        <EnhancedTableToolbar title={title}/>
        {data && !isLoading && <>
          <TableContainer className={classes.tableContainer} component={Paper} width='100%' style={{borderRadius: "6px"}}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='small'
              aria-label="enhanced table"
              stickyHeader
            >
              <EnhancedTableHead headLabels = {headLabels} rank = {rank} ranklabel={ranklabel}/>
              <TableBody>
                {data.results.map((row, index) => (
                  <TableRow key={"row" + index} className={classes.tableRow}>
                    {rank && 
                      <TableCell>
                        <Typography style={{fontSize:"14px"}}>{index + 1 + (page * pageLength)}</Typography>
                      </TableCell>
                    }
                      {dataProcessors.map((f, index2) => (
                        <TableCell key={`element ${index} ${index2}`}>
                          <Typography style={{fontSize:"14px"}}>{f(row)}</Typography>
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {(data.next || data.previous)&& (<TablePagination
            className={classes.pageination}
            rowsPerPageOptions={[pageLength]}
            component="div"
            count={data.count}
            rowsPerPage={pageLength}
            page={page}
            onPageChange={handleChangePage}
          />)}
        </>}
        <LoadingBox isLoading = {isLoading}/>
      </Paper>
    </div>
  );
}