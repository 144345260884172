import { Box, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import UnpaginatedTable from './UnpaginatedTable';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function LeagueLinks(props) {
  const { theme } = props;
  const divisionsMen = [
    {"name": "Division I", "id": 4, "regions": [
      {"name": "Great Lakes", "id": 35}, 
      {"name": "Mid-Atlantic", "id": 21}, 
      {"name": "Midwest", "id": 36}, 
      {"name": "Mountain", "id": 65}, 
      {"name": "Northeast", "id": 20}, 
      {"name": "South Central", "id": 24}, 
      {"name": "South", "id": 23}, 
      {"name": "Southeast", "id": 37}, 
      {"name": "West", "id": 22}
    ]},
    {"name": "Division II", "id": 5, "regions": [
      {"name": "Atlantic", "id": 48}, 
      {"name": "Central", "id": 19}, 
      {"name": "East", "id": 34}, 
      {"name": "Midwest", "id": 58}, 
      {"name": "South Central", "id": 45}, 
      {"name": "South", "id": 41}, 
      {"name": "Southeast", "id": 42}, 
      {"name": "West", "id": 56}
    ]},
    {"name": "Division III", "id": 6, "regions": [
      {"name": "East", "id": 33}, 
      {"name": "Great Lakes", "id": 50}, 
      {"name": "Metro", "id": 14}, 
      {"name": "Mid-Atlantic", "id": 59}, 
      {"name": "Mideast", "id": 66}, 
      {"name": "Midwest", "id": 53}, 
      {"name": "Niagara", "id": 13}, 
      {"name": "North", "id": 57}, 
      {"name": "South", "id": 52}, 
      {"name": "West", "id": 46}
    ]},
    {"name": "NAIA", "id": 3}];
  const divisionsWomen = [
    {"name": "Division I", "id": 10, "regions": [
      {"name": "Great Lakes", "id": 28}, 
      {"name": "Mid-Atlantic", "id": 29}, 
      {"name": "Midwest", "id": 38}, 
      {"name": "Mountain", "id": 64}, 
      {"name": "Northeast", "id": 25}, 
      {"name": "South Central", "id": 30}, 
      {"name": "South", "id": 26}, 
      {"name": "Southeast", "id": 27}, 
      {"name": "West", "id": 63}
    ]},
    {"name": "Division II", "id": 11, "regions": [
      {"name": "Atlantic", "id": 47}, 
      {"name": "Central", "id": 18}, 
      {"name": "East", "id": 32}, 
      {"name": "Midwest", "id": 62}, 
      {"name": "South Central", "id": 43}, 
      {"name": "South", "id": 39}, 
      {"name": "Southeast", "id": 40}, 
      {"name": "West", "id": 55}
    ]},
    {"name": "Division III", "id": 12, "regions": [ 
      {"name": "East", "id": 31}, 
      {"name": "Great Lakes", "id": 49}, 
      {"name": "Metro", "id": 16}, 
      {"name": "Mid-Atlantic", "id": 60}, 
      {"name": "Mideast", "id": 17}, 
      {"name": "Midwest", "id": 54}, 
      {"name": "Niagara", "id": 15}, 
      {"name": "North", "id": 61}, 
      {"name": "South", "id": 51},
      {"name": "West", "id": 44}
    ]},
    {"name": "NAIA", "id": 9}
  ];
  
  const headLabel = null;
  const dataProcessor = [
    (division) => (<Link to={`/leagues/${division.id}`}>{division.name}</Link>),
  ];
  const divisionExpander = (division) => {
    return(
      division.regions && <Table size="small" aria-label="ran">
        <TableHead>
          <TableRow>
            <TableCell><Typography style={{fontSize:"12px"}}><Box fontWeight="fontWeightBold" >{division.name} Regions</Box></Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {division.regions.map((region) => (
            <TableRow key={division.name + region.name}>
              <TableCell>
                <Typography style={{fontSize:"12px"}}><Link to={`/leagues/${region.id}`}>{region.name}</Link></Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
  return (
    <Container>
      <Grid container spacing={2}>       
        <Grid item xs={12} sm={6}>
          <UnpaginatedTable
            title = {"Women's Divisions/Regions"}
            localData = { divisionsWomen }
            headLabels = { headLabel } 
            dataProcessors = { dataProcessor } 
            minwidth={200}
            expander = {divisionExpander}
            rank = {false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UnpaginatedTable
            title = {"Men's Divisions/Regions"}
            localData = { divisionsMen }
            headLabels = { headLabel } 
            dataProcessors = { dataProcessor } 
            minwidth={200}
            expander = {divisionExpander}
            rank = {false}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default LeagueLinks;
