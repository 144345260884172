import { Typography } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Fragment } from "react";
import { prettyTime } from "../config";

export function RelativePlace(props){
  const { actual, predicted} = props;
  if (!predicted || (actual === predicted)){
    return (<Typography style={{fontSize:"14px"}}>{actual}</Typography>)
  }
  let red_or_green;
  let Icon;
  if (actual < predicted){
    red_or_green = green[500];
    Icon = ArrowUpwardIcon;
  } else {
    red_or_green = red[500];
    Icon = ArrowDownwardIcon;
  }

  return(
    <Fragment>
      <Typography style={{fontSize:"14px"}}>
        {actual} <Icon style={{ fontSize: 14, color : red_or_green }}/>
        <Typography display="inline" style={{fontSize:"14px", color : red_or_green}}> 
          {Math.abs(actual-predicted)} 
        </Typography>
      </Typography>
    </Fragment>
  )
}

export function RelativeScore(props){
  const { actual, predicted} = props;
  if (!predicted || (actual === predicted)){
    return (<Typography style={{fontSize:"14px"}}>{actual}</Typography>)
  }
  let red_or_green;
  let icon;
  if (actual < predicted){
    red_or_green = green[500];
    icon = "-";
  } else {
    red_or_green = red[500];
    icon = "+";
  }

  return(
    <Fragment>
      <Typography style={{fontSize:"14px"}}>
        {actual + " "} 
        <Typography display="inline" style={{fontSize:"14px", color : red_or_green}}> 
           ({icon + Math.abs(actual-predicted)})
        </Typography>
      </Typography>
    </Fragment>
  )
}

export function RelativeTime(props){
  const { actual, predicted } = props;
  if (!predicted || (Math.abs(actual - predicted) < 1)){
    return (<Typography style={{fontSize:"14px"}}>{prettyTime(actual)}</Typography>)
  }
  let red_or_green;
  let icon;
  if (actual < predicted){
    red_or_green = green[500];
    icon = "-";
  } else {
    red_or_green = red[500];
    icon = "+";
  }

  return(
    <Fragment>
      <Typography style={{fontSize:"14px"}}>
        {prettyTime(actual) + " "} 
        <Typography display="inline" style={{fontSize:"14px", color : red_or_green}}> 
           ({icon + Math.round(Math.abs(actual-predicted))})
        </Typography>
      </Typography>
    </Fragment>
  )
}