import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import MainSearch from "../components/MainSearch";
import {axios_set_data, shortDate} from "../config"
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import PaginatedTable from '../components/PaginatedTable';
import LeagueLinks from "../components/LeagueLinks";

const Home = () => {
  const [data, setData] = useState(null)

  useEffect(() => {
    axios_set_data('/api_ranking/search_runners/', setData, (x) => {})
  }, [])

  const racesURL = `/api_ranking/front_page_races/?`;

  const raceHeadLabels = [
    {label: "Date"}, 
    {label: "Race"}];

  const raceDataProcessors = [
      (race) => (shortDate(race.date)),
      (race) => (<Link to = {`/races/${race.id}`}>{race.meet_name + " - " +race.section}</Link>),
    ];
  
  return (
    <Grid className="home" container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          Welcome to LACCTiC!
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          <strong>LACCTiC</strong> stands for <strong>L</strong>ogarithmically <strong>A</strong>djusted <strong>C</strong>ross <strong>C</strong>ountry <strong>Ti</strong>me <strong>C</strong>omparisons.
          This website converts performances from varying course difficulties to their track 5k equivalents. 
          The results are used to provide sophisticated rankings and race simulations. So far <strong>{ data && data.count } runners</strong> have been analyzed.
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom> 
          For a tutorial, see the <Link to = "/tutorial/">introduction</Link>. For frequently asked questions, see the <Link to = "/faq/">FAQ</Link>.
        </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
        If you would like to help sponsor LACCTiC, please <Link to = "/contact/">contact me</Link>.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MainSearch/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1"  color="textPrimary" >
          or
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <Button component = {Link} to='/simulate/' variant="contained" color="secondary" size="large" >Start a New Simulation</Button>
      </Grid>
      <Grid item xs={12}>
      <Typography variant="h5"  color="textPrimary" gutterBottom style={{textAlign:"center"}}>
          Division and Region Links
      </Typography>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          Links to conferences are also in the top right of team pages.
        </Typography>
      </Grid>
      <LeagueLinks/>
      <Grid item xs={12} spacing = {2}>
        <PaginatedTable
          url = { racesURL } 
          title = "Recent Races" 
          headLabels = { raceHeadLabels } 
          dataProcessors = { raceDataProcessors } 
          minwidth={500}
        />
      </Grid>
    </Grid>
  )
}

export default Home;