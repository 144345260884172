import { Fragment, useState, useRef, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import logo from '../img/LACCTiC2.png';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


function FinishPieChart(props){
  const {x, y, d, one, two, three, four} = props;
  const circumference = 2 * 3.14 *d/4;
  return(
    <Fragment>
    <circle r={d/2 + 1} cx={x} cy={y}
            fill = "white"
            stroke = "black"
            strokeWidth = "2"
    />
    {four>.01 && <circle r={d/4} cx={x} cy={y}
            stroke="lightsteelblue"
            strokeWidth={d/2}
            strokeDasharray={(four * circumference)+ " " + circumference}  
            fill="none"
            transform={"rotate(270 " + x + " " + y + ")"}
    />}
    {three>.01 && <circle r={d/4} cx={x} cy={y}
            stroke="sandybrown"
            strokeWidth={d/2}
            strokeDasharray={(three * circumference)+ " " + circumference} 
            fill="none"
            transform={"rotate(270 " + x + " " + y + ")"}
    />}
    {two>.01 &&<circle r={d/4} cx={x} cy={y}
            stroke="silver"
            strokeWidth={d/2}
            strokeDasharray={(two * circumference)+ " " + circumference} 
            fill="none"
            transform={"rotate(270 " + x + " " + y + ")"}
    />}
    {one>.01 && <circle r={d/4} cx={x} cy={y}
            stroke="gold"
            strokeWidth={d/2}
            strokeDasharray={(one * circumference)+ " " + circumference} 
            fill="none"
            transform={"rotate(270 " + x + " " + y + ")"}
    />}
    </Fragment>
  )
}

function LaccticSummaryRow(props){
  const {sep, medalSep, x, y, width, height, color, best, worst, maxScore, minScore, one, two , three, four} = props;
  const scoreToX = (score) => (width * (score - minScore)/(maxScore - minScore)) + x;
  var startX = scoreToX(best)
  const barHeight = .5 * height;
  var barWidth = scoreToX(worst) - startX;
  if (barWidth < barHeight){
    startX = ((scoreToX(worst) + scoreToX(best))/2) - (barHeight/2)
    barWidth = barHeight
  }
  return (
    <Fragment>
      <rect 
        x={startX} 
        y={y + (height - barHeight)/2}
        width={barWidth} 
        height={barHeight}
        fill={color}
        rx = {barHeight /2}
        ry = {barHeight/2}
      />
      <FinishPieChart
        x={x + width + (medalSep / 2) + sep}
        y={y + height/2}
        d={height * .7}
        one = {one/100}
        two = {two/100}
        three = {three/100}
        four = {four/100}
      />
    </Fragment>
  )
}

// Team Color with White text on top
function RankingRow(props){
  const textRef = useRef(null);
  const {text, color, x, y, dx, width, height, teamLogo} = props;
  const [textLength, setTextLength] = useState(null);
  let textX = x;
  let textWidth = width;
  let addingLogo = false;
  if (teamLogo != "none" && teamLogo != ""){
    textX = x + height
    textWidth = width - height;
    addingLogo = true;
  }

  useEffect(() => {
    var textNode = textRef.current
    var bb = textNode.getBBox();
    var percentageFilled = bb.width/(textWidth - dx);
    setTextLength(percentageFilled > .95 ? .95 * (textWidth - dx) : bb.width);
  }, [onclick])
  return (
    <Fragment>
      <rect 
        x={x} 
        y={y} 
        rx={10} 
        ry={10} 
        width={width} 
        height={height} 
        fill={color} 
        stroke = "black" 
        strokeWidth={2}
      />
      <text 
        ref = {textRef} 
        fill="white" 
        fontFamily="Roboto" 
        fontSize={height * .75} 
        x={textX} 
        y={y}
        dy = {(height/1.8)}
        dx={dx}
        lengthAdjust="spacingAndGlyphs"
        textLength = {textLength}
        dominantBaseline="middle"
      >
          {text}
      </text>
      {addingLogo && <image
        x = {x + dx}
        y = {y}
        height = {height}
        width = {height}
        href = {teamLogo}
      />}
    </Fragment>
  )
}

function RowWrapper(props){
  const {
    teamOrRunner,
    leftMargin,
    rowHeight,
    sep,
    colWidth,
    maxScore,
    minScore,
    twoColTopY,
    twoColWidth,
    twoColX,
    medalSep,
    rankTeams,
    i} = props;
  
  let best;
  let worst;
  let text;
  let color;
  let teamLogo;
  if (rankTeams){
    let team = teamOrRunner;
    best = team.lower_score
    worst = team.upper_score
    text = team.name
    color = team.color
    teamLogo = team.logo
  } else {
    let runner = teamOrRunner;
    best = runner.lower_finish
    worst = runner.upper_finish
    text = runner.firstname + " " + runner.lastname;
    color = runner.team.color;
    teamLogo = runner.team.logo;
  }
  
  return (
    <Fragment>
       <RankingRow
        text = {text}
        color = {color}
        x = {leftMargin + sep}
        y = {(rowHeight * (i)) + twoColTopY}
        height = {rowHeight - sep}
        width = {colWidth - (2 * sep)}
        dx = {medalSep + 2 * sep}
        teamLogo = {teamLogo}
      />
      <LaccticSummaryRow
        x = {twoColX}
        y = {(rowHeight * (i)) + twoColTopY}
        width = {twoColWidth}
        height = {rowHeight - sep}
        color = {color}
        best = {best}
        worst = {worst}
        maxScore = {maxScore}
        minScore = {minScore}
        one = {teamOrRunner.win_chance}
        two = {teamOrRunner.two_chance}
        three = {teamOrRunner.three_chance} 
        four = {teamOrRunner.four_chance} 
        sep = {sep}
        medalSep = {medalSep}
      />
    </Fragment>
)
}

function PlaceCircle(props){
  const {place, x, y, d} = props;
  var color = "white";
  switch (place) {
    case 1:
      color = "gold";
      break;
    case 2:
      color = "silver"
      break;
    case 3:
      color = "sandybrown"
      break;
    case 4:
        color = "lightsteelblue"
        break;
    default:
      color = "white"
  }
  return (
    <Fragment>
      <circle cx={x} cy={y} r={d/2} fill={color} stroke="black" strokeWidth={d/10}/>
      <text 
        textAnchor="middle" 
        x={x} 
        y={y} 
        fontFamily="Roboto"
        fontWeight="bold"
        fontSize={d * .75}
        dy = {.1 * d}
        dx = {0}
        dominantBaseline="middle"
        fill="black"
      >
          {place}
        </text>
    </Fragment>
  )
}


export default function Publisher(props){
  const {listOfTeams, listOfRunners} = props;
  const [title, setTitle] = useState("My Ranking");
  const [rankTeams, setRankTeams] = useState(true);
  const [rankingDepth, setRankingDepth] = useState(10);
  const totalWidth = 1080;
  const totalHeight = 1080;

  const sep = 10;
  const leftMargin = 20;
  const rightMargin = 20;
  const topMargin = 110;
  const botMargin = 25;
  const colHeaderHeight = 80;
  const colHeight = totalHeight - topMargin - botMargin;
  const colWidth = (totalWidth - leftMargin - rightMargin - sep)/2;

  let itemList = listOfTeams;
  let maxScoreList = itemList.map((team) => team.upper_score).slice(0, rankingDepth);
  let minScoreList = itemList.map((team) => team.lower_score).slice(0, rankingDepth);
  if (! rankTeams){
    itemList = listOfRunners;
    maxScoreList = itemList.map((runner) => runner.upper_finish).slice(0, rankingDepth);
    minScoreList = itemList.map((runner) => runner.lower_finish).slice(0, rankingDepth);
  }
  
  const maxScore = Math.max(...maxScoreList);
  const minScore = Math.min(...minScoreList);
  const vLines = 8;
  const vLineScoreSeparator = (maxScore - minScore) / vLines;
  const finishes = [...Array(vLines + 1).keys()];


  const rowHeight = (colHeight - colHeaderHeight)/rankingDepth;
  const medalSep = .7 * rowHeight;

  const twoColX = leftMargin + colWidth + 4 *sep
  const twoColTopY = topMargin + colHeaderHeight
  const twoColWidth = colWidth - (6 * sep) - medalSep
  
  const date = new Date();
  let currentDay= String(date.getDate());
  let currentMonth = String(date.getMonth()+1);
  let currentYear = date.getFullYear();
  let currentDate = `${currentMonth}/${currentDay}/${currentYear}`;

  /*const downloadHandler = () => {
    console.log("Download SVG")
    const saveSvgAsPng = require('save-svg-as-png')
    const imageOptions = {
      scale: 1,
      encoderOptions: 1,
      backgroundColor: 'white',
    }
    let noSpaceTitle = title.replace(/\s+/g, '');
    saveSvgAsPng.saveSvgAsPng(document.getElementById('ranking-svg'), noSpaceTitle, imageOptions);
  };*/

  return(
    <Grid className="home" container spacing={2}>
    <Grid item xs = {12}>
      <ToggleButtonGroup size="small" aria-label="toggle types of rankings">
      <ToggleButton disabled={rankTeams} onClick = {() => setRankTeams(true)}>
        Teams
      </ToggleButton>
      <ToggleButton disabled = {! rankTeams} onClick = {() => setRankTeams(false)}>
        Individuals
      </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup size="small" aria-label="toggle depth of rankings">
      <ToggleButton disabled={rankingDepth == 10} onClick = {() => setRankingDepth(10)}>
        Top 10
      </ToggleButton>
      <ToggleButton disabled = {(! rankTeams) || (rankingDepth != 10)} onClick = {() => setRankingDepth(itemList.length)}>
        All
      </ToggleButton>
      </ToggleButtonGroup>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        >
        <TextField 
          id="outlined-basic" 
          label="Title" 
          variant="outlined" 
          defaultValue="My Ranking" 
          onChange = {(e) => {setTitle(e.target.value)}}
        />
        </Box>
    </Grid>
    <Grid item xs = {12}>
    <svg id={"ranking-svg"} version="1.2" viewBox="0 0 1080 1080" width="500" height="500" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="#F2F2F2" stroke = "black"/>
      <text
          textAnchor="middle"
          x="50%"
          y={topMargin - 3 * sep} 
          fontFamily="Roboto"
          fontWeight="bold"
          fontSize={.9 * colHeaderHeight}
          fill = "#424242"
        >
          {title}
      </text>
      <rect 
        rx={10} 
        ry={10} 
        x = {leftMargin} 
        y = {topMargin} 
        width={colWidth} 
        height={colHeight} 
        fill="#424242" 
      />
      <text
        textAnchor="middle"
        x={leftMargin + colWidth/2}
        y={topMargin + colHeaderHeight - 1.5 * sep} 
        fontFamily="Roboto"
        fontSize={.8 * colHeaderHeight}
        fontWeight = "bold"
        fill = "#F0F0F0"
      >
        Rank
      </text>
      <rect 
        rx={10} 
        ry={10} 
        x = {leftMargin + colWidth + sep} 
        y = {topMargin} 
        width={colWidth} 
        height={colHeight} 
        fill="#424242" 
      />
      <image
        x={leftMargin + 2 * sep + (1.2 * colWidth)}
        y={topMargin - 1.5 * sep} 
        height={1.5 * colHeaderHeight}
        href = {logo}
      />
      <rect 
        rx={10} 
        ry={10} 
        x = {leftMargin + colWidth + 2 * sep} 
        y = {topMargin + colHeaderHeight} 
        width={colWidth - 2 * sep} 
        height={colHeight - colHeaderHeight - sep} 
        fill="#F0F0F0"
        stroke = "black"
        strokeWidth="2" 
      />
      {
        finishes.map((i, j) => (
          <Fragment>
            <text
              x={twoColX + (twoColWidth * (i)) /vLines}
              y={twoColTopY + colHeight - colHeaderHeight - 1.3 * sep} 
              textAnchor="middle"
              fontWeight="bold"
              fontSize={2 * sep}
              fill = "#505050"
            >
              {Math.round(minScore + (i * vLineScoreSeparator))}
            </text>
            <text
              x={twoColX + (twoColWidth * (i)) /vLines}
              y={twoColTopY + 1.7 * sep} 
              textAnchor="middle"
              fontWeight="bold"
              fontSize={2 * sep}
              fill = "#505050"
            >
              {Math.round(minScore + (i * vLineScoreSeparator))}
            </text>
            <line 
              key = {"line" + i}
              x1={twoColX + (twoColWidth * (i)) /vLines} 
              y1={twoColTopY + 2 * sep} 
              x2={twoColX + (twoColWidth * (i)) /vLines} 
              y2={twoColTopY + colHeight - colHeaderHeight - 3 * sep}
              strokeWidth = "4"
              stroke="#B0B0B0"/>
          </Fragment>
        ))
      }
      {rankTeams && itemList.map((team, i) => {
        if (i < rankingDepth){
          return(
            <Fragment>
              <RowWrapper
                key = {"rw" + i + "-" + rankingDepth}
                i = {i}
                rankTeams = {rankTeams}
                teamOrRunner = {team}
                leftMargin = {leftMargin}
                topMargin = {topMargin}
                colHeaderHeight = {colHeaderHeight}
                rowHeight = {rowHeight}
                colWidth = {colWidth}
                maxScore = {maxScore}
                minScore = {minScore}
                sep = {sep}
                medalSep = {medalSep}
                twoColX = {twoColX}
                twoColTopY = {twoColTopY}
                twoColWidth = {twoColWidth}
              />
              <PlaceCircle
                key = {"place" + i + "-" + rankingDepth}
                place = {i+1}
                x = {leftMargin + 2 * sep + medalSep/2}
                y = {(i + .5) * rowHeight + topMargin + colHeaderHeight - sep/2}
                d = {medalSep}
              />
            </Fragment>
          )
        }}
      )}
      {!rankTeams && itemList.map((team, i) => {
        if (i < rankingDepth){
          return(
            <Fragment>
              <RowWrapper
                key = {"rw" + i + "-" + rankingDepth}
                i = {i}
                rankTeams = {rankTeams}
                teamOrRunner = {team}
                leftMargin = {leftMargin}
                topMargin = {topMargin}
                colHeaderHeight = {colHeaderHeight}
                rowHeight = {rowHeight}
                colWidth = {colWidth}
                maxScore = {maxScore}
                minScore = {minScore}
                sep = {sep}
                medalSep = {medalSep}
                twoColX = {twoColX}
                twoColTopY = {twoColTopY}
                twoColWidth = {twoColWidth}
              />
              <PlaceCircle
                key = {"place" + i + "-" + rankingDepth}
                place = {i+1}
                x = {leftMargin + 2 * sep + medalSep/2}
                y = {(i + .5) * rowHeight + topMargin + colHeaderHeight - sep/2}
                d = {medalSep}
              />
            </Fragment>
          )
        }}
      )}
      <text
        x="50%"
        y={totalHeight - .5 * botMargin} 
        textAnchor="middle"
        fontWeight="bold"
        fontSize={2 * sep}
        fill = "#505050"
        dominantBaseline="middle"
      >
        Generated on {currentDate} for a custom field using www.lacctic.com
      </text>
    </svg>
    </Grid>
  </Grid>
  )
}