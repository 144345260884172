import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import logo from '../img/LACCTiC2.png';
import logoglow from '../img/LACCTiC2glow.png';
import { lightBlue, grey } from '@material-ui/core/colors'
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
  },
  glowButton: {
    color: 'white',
    '&:hover': {
      color: lightBlue[400],
      backgroundColor: grey[800],
    },
    transition: "color .2s ease-in-out",
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: "0",
    padding: 0
  },
  overallToolbar: {
    padding: 0,
    margin: -7
  },
  donateButton: {
    marginRight: 0
  },
  logoButton: {
    padding: 0,
    backgroundSize: "190px",
    backgroundImage: `url(${logo})`,
    backgroundColor: 'transparent',
    "&:hover": {
      colorPrimary: 'red', 
      backgroundImage: `url(${logoglow})`,
      backgroundColor: 'transparent',
    },
    transition: "background-image .2s ease-in-out"
  },
  logoButtonMobile: {
    padding: 0,
    backgroundSize: "140px",
    backgroundColor: 'transparent',
    "&:hover": {
      colorPrimary: 'red', 
      backgroundImage: `url(${logoglow})`,
      backgroundColor: 'transparent',
    },
  },
}));

function Header() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div className={classes.root}>
      <AppBar>
        <Container maxWidth="md">
        <Toolbar className = {classes.overallToolbar}>
          {isMobile &&
          <Button disableRipple={true} className={classes.logoButtonMobile} aria-label="iconhomebutton" component = {Link} to="/">
            <img className={classes.image_logo} src={logo} width="140" alt="logo" />
          </Button>}
          {!isMobile &&
          <Button disableRipple={true} className={classes.logoButton} aria-label="iconhomebutton" component = {Link} to="/">
            <img className={classes.image_logo} src={logo} width="190" alt="logo" />
          </Button>}
          {!isMobile && <section className={classes.rightToolbar}>
            <Button className = {classes.glowButton} component = {Link} to="/about/" size="medium"> About</Button>
            <Button className= {classes.glowButton} component = {Link} to="/contact/" size="medium" >Contact</Button>
            <Button className= {classes.donateButton} component = {Link} to='/donate/' variant="contained" color="secondary" size="medium">Donate</Button>
          </section>}
          {isMobile && <section className={classes.rightToolbar}>
            <Button className = {classes.glowButton} component = {Link} to="/about/" size="small"> About</Button>
            <Button className= {classes.glowButton} component = {Link} to="/contact/" size="small" >Contact</Button>
            <Button className= {classes.donateButton} component = {Link} to='/donate/' variant="contained" color="secondary" size="small">Donate</Button>
          </section>}
        </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default Header;